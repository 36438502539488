import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import ProjectDataService from '../services/project.service';
// import SubProjectDataService from '../services/sub-project.service';
import { Project, SubProjectResource, SubProject } from '../models/interfaces';

export interface ProjectsState extends ReturnType<typeof projectsAdapter.getInitialState> {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    first: Project | undefined;
    last: Project | undefined;
}

const projectsAdapter = createEntityAdapter<Project>({
    sortComparer: (a, b) => b.project_code.localeCompare(a.project_code),
});

const initialState: ProjectsState = {
    ...projectsAdapter.getInitialState(),
    status: 'idle',
    error: null,
    first: undefined,
    last: undefined,
};

export const retrieveProjects = createAsyncThunk(
    'projects/retrieve',
    async (_, { dispatch }) => {
        const allProjects: Project[] = [];
        let nextUrl = `${ProjectDataService.projectBaseUrl}/?limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await ProjectDataService.getProjectsByUrl(nextUrl).then((value) => {
                const data = value.data;
                allProjects.push(...data.results);
                dispatch(addProjects(data.results));
                nextUrl = data.next;
            });
        }
        return allProjects;
    }
);

export const retrieveProjectsAfterId = createAsyncThunk(
    'projects/retrieve_after_id',
    async (props: { id: number }) => {
        const res = await ProjectDataService.getProjectsAfterId(props.id);
        return res.data.results;
    }
);

export const retrieveProjectLastProjectCode = createAsyncThunk(
    'projects/retrieve_last_project_code',
    async () => {
        const res = await ProjectDataService.getProjectLastProjectCode();
        return res.data.results;
    }
);

export const retrieveProjectsBetweenDateTime = createAsyncThunk(
    'projects/retrieve_between_datetime',
    async (props: { start: string, end: string }, { dispatch }) => {
        const allProjects: Project[] = [];
        let nextUrl = `${ProjectDataService.projectBaseUrl}/?add_to_list_date__gte=${props.start}&add_to_list_date__lte=${props.end}&limit=10&ordering=-id`;
        // let nextUrl = `${ProjectDataService.projectBaseUrl}/?server_timestamp_created__gte=${props.start}&server_timestamp_created__lte=${props.end}&limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await ProjectDataService.getProjectsByUrl(nextUrl).then((value) => {
                const data = value.data;
                allProjects.push(...data.results);
                dispatch(addProjects(data.results));
                nextUrl = data.next;
            });
        }
        return allProjects;
    }
);

export const retrieveProjectsAfterServerTimestampUpdated = createAsyncThunk(
    'projects/retrieve_after_server_timestamp_updated',
    async (props: { server_timestamp_updated: string }, { dispatch }) => {
        const allProjects: Project[] = [];
        let nextUrl = `${ProjectDataService.projectBaseUrl}/?server_timestamp_updated__gt=${props.server_timestamp_updated}&limit=500&ordering=-id`;
        while (nextUrl !== null) {
            await ProjectDataService.getProjectsByUrl(nextUrl).then((value) => {
                const data = value.data;
                allProjects.push(...data.results);
                dispatch(addProjects(data.results));
                nextUrl = data.next;
            });
        }
        return allProjects;
    }
);

export const retrieveProjectFirstAndLastBetweenDateTime = createAsyncThunk(
    'projects/retrieve_between_datetime_first_and_last',
    async (props: { start: string, end: string }) => {
        const [res_first, res_last] = await Promise.all([
            ProjectDataService.getProjectFirstBetweenDateTime(props.start, props.end),
            ProjectDataService.getProjectLastBetweenDateTime(props.start, props.end),
        ]);
        const results = { first: res_first.data.results, last: res_last.data.results };
        return results;
    }
);

export const assignProject = createAsyncThunk(
    'projects/assign_project',
    async (props: { sub_project_ids: number[], route_ids: Number[], work_ids: Number[], do_remove: Boolean }) => {
        const res = await ProjectDataService.postAssignSubProject(props.sub_project_ids, props.route_ids, props.work_ids, props.do_remove);
        return res.data.results;
    }
);

export const updateServerProject = createAsyncThunk(
    'projects/update_server_project',
    async (props: { project: Project }) => {
        const res = await ProjectDataService.postUpdateProject(props.project);
        return res.data.results;
    }
);

export const addServerProject = createAsyncThunk(
    'projects/add_server_project',
    async (props: { project: Project }) => {
        const res = await ProjectDataService.postAddProject(props.project);
        return res.data;
    }
);


export const retrieveProjectsById = createAsyncThunk(
    'projects/retrieve_by_id',
    async (props: { ids: number[] }) => {
        const res = await ProjectDataService.getProjectsById(props.ids);
        return res.data.results;
    }
);

export const addServerSubProject = createAsyncThunk(
    'projects/add_server_sub_project',
    async (props: { sub_project: SubProject }) => {
        const res = await ProjectDataService.postAddSubProject(props.sub_project);
        return res.data
    }
);

export const removeServerSubProject = createAsyncThunk(
    'projects/remove_server_sub_project',
    async (props: { sub_project: SubProject }) => {
        const res = await ProjectDataService.deleteSubProject(props.sub_project);
        return res.data.results;
    }
);

export const unlinkServerSubProjectFromProject = createAsyncThunk(
    'projects/unlink_server_sub_project_from_project',
    async (props: { sub_project: SubProject }) => {
        const res = await ProjectDataService.unlinkSubProjectFromProject(props.sub_project);
        return res.data.results;
    }
);

export const updateServerSubProject = createAsyncThunk(
    'projects/update_server_sub_project',
    async (props: { sub_project: SubProject }) => {
        const res = await ProjectDataService.putUpdateSubProject(props.sub_project);
        return res.data.results;
    }
);

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        addProjects: (state, action: PayloadAction<Project[]>) => {
            projectsAdapter.upsertMany(state, action.payload);
        },
        updateProjects: (state, action: PayloadAction<Project[]>) => {
            const updatePayload = action.payload.map((project) => ({ id: project.id, changes: project }));
            projectsAdapter.updateMany(state, updatePayload);
        },
        removeProjects: (state, action: PayloadAction<Project[]>) => {
            const idsToRemove = action.payload.map((project) => project.id);
            projectsAdapter.removeMany(state, idsToRemove);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveProjects.pending, (state, action) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveProjects.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching projects.';
        });
        builder.addCase(retrieveProjects.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            projectsAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveProjectsBetweenDateTime.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveProjectsBetweenDateTime.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching projects.';
        });
        builder.addCase(retrieveProjectsBetweenDateTime.fulfilled, (state, action) => {
            state.status = 'succeeded';
            projectsAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveProjectsById.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(retrieveProjectsById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching projects.';
        });
        builder.addCase(retrieveProjectsById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
            projectsAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(retrieveProjectFirstAndLastBetweenDateTime.pending, (state, action) => {
            // state.status = 'loading';
            state.first = undefined;
            state.last = undefined;
        });
        builder.addCase(retrieveProjectFirstAndLastBetweenDateTime.rejected, (state, action) => {
            // state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching projects.';
        });
        builder.addCase(retrieveProjectFirstAndLastBetweenDateTime.fulfilled, (state, action) => {
            // state.status = 'succeeded';
            state.error = null;
            state.first = action.payload.first[0];
            state.last = action.payload.last[0];
        });

        builder.addCase(addServerProject.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(addServerProject.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching projects.';
        });
        builder.addCase(addServerProject.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(updateServerProject.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(updateServerProject.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'An error occurred while fetching projects.';
        });
        builder.addCase(updateServerProject.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        });

        builder.addCase(addServerSubProject.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(addServerSubProject.rejected, (state, action) => {
            state.error = action.error.message || 'An error occurred while fetching projects.';
            state.status = 'failed';
        })
        builder.addCase(addServerSubProject.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        })

        builder.addCase(retrieveProjectLastProjectCode.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(retrieveProjectLastProjectCode.rejected, (state, action) => {
            state.error = action.error.message || 'An error occurred while fetching projects.';
            state.status = 'failed';
        })
        builder.addCase(retrieveProjectLastProjectCode.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        })

        builder.addCase(unlinkServerSubProjectFromProject.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(unlinkServerSubProjectFromProject.rejected, (state, action) => {
            state.error = action.error.message || 'An error occurred while fetching projects.';
            state.status = 'failed';
        })
        builder.addCase(unlinkServerSubProjectFromProject.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.error = null;
        })

        // builder.addCase(updateServerSubProject.pending, (state, action) => {
        //     state.status = 'loading';
        // })
        // builder.addCase(updateServerSubProject.rejected, (state, action) => {
        //     state.error = action.error.message || 'An error occurred while fetching projects.';
        //     state.status = 'failed';
        // })
        // builder.addCase(updateServerSubProject.fulfilled, (state, action) => {
        //     state.status = 'succeeded';
        //     state.error = null;
        // })
    },
});

export const {
    addProjects,
    updateProjects,
    removeProjects,
} = projectSlice.actions

export const { selectAll: selectAllProjects, selectById: selectProjectById } = projectsAdapter.getSelectors(
    (state: { projects: ProjectsState }) => state.projects
);

// export const getSubProjectsStatus = ((state: { sub_projects: SubProjectsState }) => state.sub_projects.status);
// export const getSubProjectsError = ((state: { sub_projects: SubProjectsState }) => state.sub_projects.error);
// export const getSubProjectsCount = ((state: { sub_projects: SubProjectsState }) => state.sub_projects.count);

// export const selectSubProjectsByProjectId = createSelector(
//     [selectAllSubProjects, (state, project_id) => project_id],
//     (sub_projects, project_id) => sub_projects.filter(sub_project => sub_project.project === project_id)
// )

// export const selectSubProjectIdsByProjectId = createSelector(
//     [selectAllSubProjects, (state, project_id) => project_id],
//     (sub_projects, project_id) => sub_projects.filter(sub_project => sub_project.project === project_id).map(sub_project => sub_project.id)
// )

// export const selectDefaultSubProjectByProjectId = createSelector(
//     [selectAllSubProjects, (state, project_id) => project_id],
//     (sub_projects, project_id) => sub_projects.find(sub_project => (sub_project.project === project_id) && (sub_project.name.toLowerCase() === 'default'))
// )

export default projectSlice.reducer;
