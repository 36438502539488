import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Holidays from 'date-holidays';
import { Route, Work } from '../../models/interfaces';
const holidays = new Holidays('ZA');

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore);


export const calculateOfficialWorkHours = (period_start: Dayjs, period_end: Dayjs) => {
    if (!period_end.isAfter(period_start)) return 0;
    const work_start_hour = 7;      // 7:00
    const work_end_hour = 16;       // 16:00
    const work_hours_per_day = 8;   // 9 hours - 1 hour lunch
    const lunch_start_hour = 12;
    const lunch_end_hour = 13;
    let total_work_hours = 0;
    let current_day = period_start.startOf('day');
    const last_day = period_end.startOf('day');

    while (current_day.isSameOrBefore(last_day)) {
        if (current_day.day() !== 0 && current_day.day() !== 6) { // Skip weekends
            let day_start = current_day.hour(work_start_hour).minute(0).second(0);
            let day_end = current_day.hour(work_end_hour).minute(0).second(0);

            // Adjust first day if period starts after work_start_hour
            if (current_day.isSame(period_start, 'day')) {
                day_start = period_start;
            }

            // Adjust last day if period ends before work_end_hour
            if (current_day.isSame(period_end, 'day')) {
                day_end = period_end;
            }

            // Calculate hours for this day
            if (day_end.isAfter(day_start)) {
                // If the day_start is before work hours, set it to work_start_hour
                if (day_start.hour() < work_start_hour) {
                    day_start = day_start.hour(work_start_hour).minute(0).second(0);
                }

                // If the day_end is after work hours, set it to work_end_hour
                if (day_end.hour() > work_end_hour) {
                    day_end = day_end.hour(work_end_hour).minute(0).second(0);
                }

                let hours = day_end.diff(day_start, 'hour');
                let minutes = day_end.diff(day_start, 'minute') % 60;
                let total_hours = hours + (minutes / 60);

                // Adjust for lunch break
                const lunch_start = current_day.hour(lunch_start_hour).minute(0).second(0);
                const lunch_end = current_day.hour(lunch_end_hour).minute(0).second(0);
                if (day_start.isBefore(lunch_end) && day_end.isAfter(lunch_start)) {
                    total_hours = Math.max(0, total_hours - 1);
                }

                // Ensure we don't exceed work_hours_per_day
                total_hours = Math.min(total_hours, work_hours_per_day);
                total_hours = Math.max(total_hours, 0);

                // Round to 2 decimal places to avoid floating point issues
                total_work_hours += Math.round(total_hours * 100) / 100;
            }
        }

        current_day = current_day.add(1, 'day');
    }

    return total_work_hours;
}

export const calculateEmployeeWorkHours = (works: Work[]) => {
    const duration_s = works.reduce((total_duration, work) => {
        return total_duration + work.device_duration;
    }, 0);
    const duration = dayjs.duration(duration_s, 'seconds');
    const hours = duration.asHours();
    return hours;
}



export const calculateEmployeeTravelHours = (routes: Route[]) => {
    const duration_s = routes.reduce((total_duration, route) => {
        return total_duration + route.device_duration;
    }, 0);
    const duration = dayjs.duration(duration_s, 'seconds');
    const hours = duration.asHours();
    return hours;
}

export const calculateEmployeeAllocatedWorkHours = (works: Work[]) => {
    const duration_s = works.reduce((total_duration, work) => {
        return work.list_of_sub_projects.length > 0 ? total_duration + work.device_duration : total_duration;
    }, 0);
    const duration = dayjs.duration(duration_s, 'seconds');
    const hours = duration.asHours();
    return hours;
}

export const calculateEmployeeAllocatedTravelHours = (routes: Route[]) => {
    const duration_s = routes.reduce((total_duration, route) => {
        return route.list_of_sub_projects.length > 0 ? total_duration + route.device_duration : total_duration;
    }, 0);
    const duration = dayjs.duration(duration_s, 'seconds');
    const hours = duration.asHours();
    return hours;
}

export const calculateEmployeeUnallocatedWorkHours = (works: Work[]) => {
    const duration_s = works.reduce((total_duration, work) => {
        return work.list_of_sub_projects.length === 0 ? total_duration + work.device_duration : total_duration;
    }, 0);
    const duration = dayjs.duration(duration_s, 'seconds');
    const hours = duration.asHours();
    return hours;
}

export const calculateEmployeeUnallocatedTravelHours = (routes: Route[]) => {
    const duration_s = routes.reduce((total_duration, route) => {
        return route.list_of_sub_projects.length === 0 ? total_duration + route.device_duration : total_duration;
    }, 0);
    const duration = dayjs.duration(duration_s, 'seconds');
    const hours = duration.asHours();
    return hours;
}