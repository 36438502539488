import { Checkbox, Chip, CircularProgress, IconButton, List, Menu, Paper, Popover, Select, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Add from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import { AppDispatch } from '../../store';
import { TimesheetProjectsTableRow } from './timesheet-projects-table-row';
import { ProjectsState, selectAllProjects } from '../../slices/projects';
import { ProjectDataGridSearch } from '../project/project-data-grid/project-data-grid-search';

export const TimesheetProjectsTable = (
    props: { handleProjectSelected: any }) => {
    const projects = useSelector(selectAllProjects);
    const [filtered_projects, setFilteredProjects] = useState(projects);
    const [filter_search, setFilterSearch] = useState('');

    useEffect(() => {
        setFilteredProjects(projects.filter((project) => project.project_code.toLowerCase().includes(filter_search.toLowerCase()) || project.description.toLowerCase().includes(filter_search.toLowerCase())));
    }, [
        projects,
        filter_search
    ])

    return (
        <Stack
            direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            sx={{ p: 0.5, py: 0, maxHeight: '400px', maxWidth: '400px' }}
        >
            <Table
                stickyHeader={true}
                size={'small'}
                onClick={(e) => e.stopPropagation()}
            >
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <ProjectDataGridSearch setFilterSearch={setFilterSearch} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        hover
                        onClick={(e) => {
                            e.stopPropagation();
                            props.handleProjectSelected(undefined)
                        }}
                    >
                        <TableCell colSpan={2} >
                            None
                        </TableCell>
                    </TableRow>
                    {filtered_projects.map((project) =>
                        <TimesheetProjectsTableRow
                            key={`${project.id}`}
                            project={project}
                            handleProjectSelected={props.handleProjectSelected}
                        />
                    )}
                </TableBody>
            </Table>
        </Stack>
    );
}