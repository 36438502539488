import { Badge, Button, CircularProgress, IconButton, Menu, Stack, Tooltip, Typography } from "@mui/material";
import { Asset, AssetRoute, AssetWork, Rate, Route, Work } from "../../models/interfaces";
import { Add, DeleteOutline, Engineering } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AssetsState } from "../../slices/assets";
import { useEffect, useState } from "react";
import MemoryIcon from '@mui/icons-material/Memory';
import { AppDispatch } from "../../store";
import { retrieveWorksById, updateServerWork } from "../../slices/works";
import { retrieveRoutesById, updateServerRoute } from "../../slices/routes";
import { FilterState } from "../../slices/filter";


export const AssetInfoRouteWorkLink = (props: { route: Route | undefined, work: Work | undefined }) => {
    const dispatch = useDispatch<AppDispatch>();
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const [anchor_asset_menu, setAnchorAssetMenu] = useState<null | HTMLElement>(null)
    const [open_asset_menu, setOpenAssetMenu] = useState(false);
    const [asset_link_busy, setAssetLinkBusy] = useState(false);
    const [route, setRoute] = useState<Route | undefined>();
    const [work, setWork] = useState<Work | undefined>();

    useEffect(() => {
        //console.log("child trigger", props.route, props.work);
        if (props.route) {
            const r = filters_state.route_filter.routes.find(r => r.id === props.route!.id);
            setRoute(r);
        }
        if (props.work) {
            const w = filters_state.work_filter.works.find(w => w.id === props.work!.id);
            setWork(w);
        }
    }, [
        props.route,
        props.work,
        props.route?.asset_routes.length,
        props.work?.asset_works.length,
        filters_state.route_filter.routes,
        filters_state.work_filter.works
    ]);

    const handleUpdateWork = (updated_work: Work) => {
        if (updated_work) {
            setAssetLinkBusy(true);
            dispatch(updateServerWork({ work: updated_work })).then((result) => {
                dispatch(retrieveWorksById({ ids: [updated_work.id] }));
                setAssetLinkBusy(false);
                console.log("updated work");
            });
        }
    }

    const getAssetWorksForUpdate = (asset_works: AssetWork[]) => {
        return asset_works.map(asset_work => {
            return {
                ...asset_work,
                asset: undefined,
                rate_id: undefined,
            }
        });
    }

    const addAssetWorkToWork = (asset: Asset, rate: Rate, work: Work) => {
        console.log("add asset to work");
        const new_asset_work: AssetWork = {
            id: 0,
            server_timestamp_created: null,
            server_timestamp_updated: null,
            asset_id: asset.id,
            asset: undefined,
            rate_id: rate?.id,
            rate: rate,
            work_id: work.id,
            needs_approval: false,
            auto_linked: false,
        }
        const updated_work: Work = {
            ...work,
            asset_works: [...getAssetWorksForUpdate(work.asset_works), new_asset_work]
        }
        handleUpdateWork(updated_work);
    };

    const deleteAssetWorkFromWork = (asset_work: AssetWork, work: Work) => {
        const updated_work: Work = {
            ...work,
            asset_works: getAssetWorksForUpdate(work.asset_works).filter(aw => aw.id !== asset_work.id)
        }
        handleUpdateWork(updated_work);
    };

    const handleUpdateRoute = (updated_route: Route) => {
        if (updated_route) {
            setAssetLinkBusy(true);
            dispatch(updateServerRoute({ route: updated_route })).then((result) => {
                dispatch(retrieveRoutesById({ ids: [updated_route.id] }));
                setAssetLinkBusy(false);
                console.log("updated route");
            });
        }
    }

    const getAssetRoutesForUpdate = (asset_routes: AssetRoute[]) => {
        return asset_routes.map(asset_route => {
            return {
                ...asset_route,
                asset: undefined,
                rate_id: undefined,
            }
        });
    }

    const addAssetRouteToRoute = (asset: Asset, rate: Rate, route: Route) => {
        console.log("add asset to route");
        const new_asset_route: AssetRoute = {
            id: 0,
            server_timestamp_created: null,
            server_timestamp_updated: null,
            asset_id: asset.id,
            asset: undefined,
            rate_id: rate?.id,
            rate: rate,
            route_id: route.id,
            needs_approval: false,
            auto_linked: false,
        }
        const updated_route: Route = {
            ...route,
            asset_routes: [...getAssetRoutesForUpdate(route.asset_routes), new_asset_route]
        }
        handleUpdateRoute(updated_route);
    };

    const deleteAssetRouteFromRoute = (asset_route: AssetRoute, route: Route) => {
        const updated_route: Route = {
            ...route,
            asset_routes: getAssetRoutesForUpdate(route.asset_routes).filter(ar => ar.id !== asset_route.id)
        }
        handleUpdateRoute(updated_route);
    };

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                {
                    (route && !work) &&
                    <Badge
                        overlap='circular'
                        badgeContent=
                        {
                            asset_link_busy ? '' : route.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === false).length ?
                                (route.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee').length)
                                :
                                (route.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === true).length)
                        }
                        sx={{ p: 0.5, color: 'gray' }}
                    >
                        <Tooltip title={route.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee').map((ar: AssetRoute) => ar.asset?.name).join(', ')}>
                            <IconButton
                                size='small'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorAssetMenu(e.currentTarget);
                                    setOpenAssetMenu(true);
                                }}
                            >
                                {
                                    asset_link_busy ? <CircularProgress size={'1rem'} /> :
                                        (route.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === false).length > 0) ?
                                            <Engineering fontSize='inherit' color='disabled' />
                                            :
                                            (route.asset_routes.filter((ar: AssetRoute) => ar?.asset?.asset_type?.toLowerCase() === 'employee' && ar.auto_linked === true).length > 0) ?
                                                <MemoryIcon fontSize='inherit' color='inherit' />
                                                :
                                                <Add fontSize='inherit' color='primary' />
                                }
                            </IconButton>
                        </Tooltip>
                    </Badge>
                }
                {
                    (!route && work) &&
                    <Badge
                        overlap='circular'
                        badgeContent=
                        {
                            asset_link_busy ? '' : work.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === false).length ?
                                (work.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee').length)
                                :
                                (work.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === true).length)
                        }
                        sx={{ p: 0.5, color: 'gray' }}
                    >
                        <Tooltip title={work.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee').map((aw: AssetWork) => aw.asset?.name).join(', ')}>
                            <IconButton
                                size='small'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorAssetMenu(e.currentTarget);
                                    setOpenAssetMenu(true);
                                }}
                            >
                                {
                                    asset_link_busy ? <CircularProgress size={'1rem'} /> :
                                        (work.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === false).length > 0) ?
                                            <Engineering fontSize='inherit' color='disabled' />
                                            :
                                            (work.asset_works.filter((aw: AssetWork) => aw?.asset?.asset_type?.toLowerCase() === 'employee' && aw.auto_linked === true).length > 0) ?
                                                <MemoryIcon fontSize='inherit' color='inherit' />
                                                :
                                                <Add fontSize='inherit' color='primary' />
                                }
                            </IconButton>
                        </Tooltip>
                    </Badge>

                }
                <Menu
                    open={open_asset_menu}
                    anchorEl={anchor_asset_menu}
                    onClose={(e) => {
                        setOpenAssetMenu(false);
                        setAnchorAssetMenu(null);
                    }}
                    transitionDuration={0}
                >
                    {
                        [...assets_state.assets].filter(asset => asset.asset_type.toLowerCase() === 'employee')
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(asset => {
                                return (
                                    <Stack
                                        key={`${asset.id}`}
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        width={'100%'}
                                    >
                                        <Button
                                            fullWidth
                                            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
                                            color='inherit'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (!route && work) {
                                                    if (!work.asset_works.map((aw: AssetWork) => aw?.asset?.id).includes(asset.id)) {
                                                        addAssetWorkToWork(asset, asset.rate, work);
                                                    } else {
                                                        const asset_work = work.asset_works.find((aw: AssetWork) => aw.asset?.id === asset.id);
                                                        if (asset_work) {
                                                            deleteAssetWorkFromWork(asset_work, work);
                                                        }
                                                    }
                                                }
                                                if (route && !work) {
                                                    if (!route.asset_routes.map((ar: AssetRoute) => ar?.asset?.id).includes(asset.id)) {
                                                        addAssetRouteToRoute(asset, asset.rate, route);
                                                    } else {
                                                        const asset_route = route.asset_routes.find((ar: AssetRoute) => ar.asset?.id === asset.id);
                                                        if (asset_route) {
                                                            deleteAssetRouteFromRoute(asset_route, route);
                                                        }
                                                    }
                                                }
                                                if (!e.ctrlKey) {
                                                    setAnchorAssetMenu(null);
                                                    setOpenAssetMenu(false);
                                                }
                                            }}
                                        >
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} spacing={1}>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
                                                    {
                                                        <MemoryIcon
                                                            fontSize='small'
                                                            color='disabled'
                                                            sx={{
                                                                opacity:
                                                                    (
                                                                        (work &&
                                                                            work.asset_works.filter((aw: AssetWork) => aw?.asset?.id === asset.id && aw.auto_linked).length > 0) ||
                                                                        (route &&
                                                                            route.asset_routes.filter((ar: AssetRoute) => ar?.asset?.id === asset.id && ar.auto_linked).length > 0)
                                                                    ) ? '100%' : '0%'
                                                            }}
                                                        />
                                                    }
                                                    <Typography fontSize={'inherit'} align='left'>
                                                        {asset.name}
                                                    </Typography>
                                                </Stack>
                                                {
                                                    asset_link_busy ?
                                                        <CircularProgress size={'1rem'} />
                                                        :
                                                        <>
                                                            {
                                                                ((work && !work.asset_works.map((aw: AssetWork) => aw?.asset?.id).includes(asset.id)) ||
                                                                    (route && !route.asset_routes.map((ar: AssetRoute) => ar?.asset?.id).includes(asset.id)))
                                                                &&
                                                                <Add color='primary' fontSize='small' />
                                                            }
                                                            {
                                                                ((work && work.asset_works.map((aw: AssetWork) => aw?.asset?.id).includes(asset.id)) ||
                                                                    (route && route.asset_routes.map((ar: AssetRoute) => ar?.asset?.id).includes(asset.id)))
                                                                &&
                                                                <DeleteOutline color='error' fontSize='small' />
                                                            }
                                                        </>
                                                }
                                            </Stack>
                                        </Button>
                                    </Stack>
                                )
                            })
                    }
                </Menu>
            </Stack>
        </>
    );
};
