import { useState, useEffect, useRef } from 'react';
import { Checkbox, Chip, IconButton, List, Menu, Paper, Popover, Select, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, Tooltip, Button } from '@mui/material';
import { Asset, Route, Work } from '../../../models/interfaces';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from '@mui/lab';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Map } from 'leaflet';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AddLinkIcon from '@mui/icons-material/AddLink';
import StraightenIcon from '@mui/icons-material/Straighten';
import { FilterState, addSelectFilterSelectedRoutes, addSelectFilterSelectedWorks, removeSelectFilterSelectedRoutes, removeSelectFilterSelectedWorks, setSelectFilterSelectedAsset, setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { AssetInfoTableRow } from './asset-info-table-row';
import { ProjectAssignMenu } from '../../project/project-assign-menu/project-assign-menu';
import { RoutesState } from '../../../slices/routes';
import { WorksState } from '../../../slices/works';
import { formatDuration } from '../../project/project-utils';
dayjs.extend(relativeTime)
dayjs.extend(duration)

const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
        zIndex: '10',
    } as React.CSSProperties,
};

export const AssetInfoTable = (props: { map: Map, asset: Asset, routes: Route[], works: Work[] }) => {
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const dispatch = useDispatch<AppDispatch>();

    const [objects, setObjects] = useState<any[]>([]);

    const isSelected = (object: any) => {
        // return selected.indexOf(object.id) !== -1;
        if (object.type == 'Route') {
            return filter_state.select_filter.selected_routes.map((route) => route.id).includes(object.content.id);
        }
        if (object.type == 'Work') {
            return filter_state.select_filter.selected_works.map((work) => work.id).includes(object.content.id);
        }
    }

    useEffect(() => { //when asset changes, unselected routes and works of other assets
        dispatch(setSelectFilterSelectedAsset(props.asset));
        filter_state.select_filter.selected_routes.forEach((route) => {
            if (route.tag_id != props.asset.tag_id) {
                dispatch(removeSelectFilterSelectedRoutes([route]));
            }
        })
        filter_state.select_filter.selected_works.forEach((work) => {
            if (work.tag_id != props.asset.tag_id) {
                dispatch(removeSelectFilterSelectedWorks([work]));
            }
        })
    }, [props.asset])

    useEffect(() => { //When source routes/works change, update selected routes/works as well
        const selected_routes = filter_state.select_filter.selected_routes;
        const selected_works = filter_state.select_filter.selected_works;
        const updated_selected_routes: Route[] = selected_routes
            .map((selected_route) =>
                routes_state.routes.find((route) => route.id === selected_route.id)
            )
            .filter((selected_route): selected_route is Route => selected_route !== undefined);

        const updated_selected_works: Work[] = selected_works
            .map((selected_work) =>
                works_state.works.find((work) => work.id === selected_work.id)
            )
            .filter((selected_work): selected_work is Work => selected_work !== undefined);

        dispatch(setSelectFilterSelectedRoutes(updated_selected_routes));
        dispatch(setSelectFilterSelectedWorks(updated_selected_works));
    }, [props.routes, props.works])

    useEffect(() => {
        setObjects([]);
        props.routes.forEach((route) => {
            setObjects((o) => [...o, { type: 'Route', content: route, id: `Route-${route.id}` }])
        });
        props.works.forEach((work) => {
            setObjects((o) => [...o, { type: 'Work', content: work, id: `Work-${work.id}` }])
        });
        setObjects((o) => o.sort((a, b) => {
            return dayjs(a.content.device_start_time).unix() - dayjs(b.content.device_start_time).unix()
        }));
    }, [props.routes, props.works, props.asset])

    const handleSelectAllClick = (e: any) => {
        dispatch(setSelectFilterSelectedAsset(props.asset));
        if (e.target.checked == true) {
            objects.forEach((object) => {
                if (object.type == 'Route') {
                    dispatch(addSelectFilterSelectedRoutes([object.content]));
                }
                if (object.type == 'Work') {
                    dispatch(addSelectFilterSelectedWorks([object.content]));
                }
            });
            return;
        }
        dispatch(setSelectFilterSelectedRoutes([]));
        dispatch(setSelectFilterSelectedWorks([]));
    };

    const handleSelectAllUnlinkedClick = (e: any) => {
        const unlinked_objects = objects.filter((o) => { return o.content.list_of_sub_projects.length == 0 }).map((o) => o.id);
        dispatch(setSelectFilterSelectedRoutes([]));
        dispatch(setSelectFilterSelectedWorks([]));
        unlinked_objects.forEach((object) => {
            if (object.type == 'Route') {
                dispatch(addSelectFilterSelectedRoutes([object.content]));
            }
            if (object.type == 'Work') {
                dispatch(addSelectFilterSelectedWorks([object.content]));
            }
        });
    };


    const handleClickCheckbox = (object: any) => {
        dispatch(setSelectFilterSelectedAsset(props.asset));
        if (object.type == 'Route') {
            if (filter_state.select_filter.selected_routes.map((route) => route.id).includes(object.content.id)) {
                dispatch(removeSelectFilterSelectedRoutes([object.content]));
            }
            else {
                dispatch(addSelectFilterSelectedRoutes([object.content]));
            }
        }
        if (object.type == 'Work') {
            if (filter_state.select_filter.selected_works.map((work) => work.id).includes(object.content.id)) {
                dispatch(removeSelectFilterSelectedWorks([object.content]));
            }
            else {
                dispatch(addSelectFilterSelectedWorks([object.content]));
            }
        }
    };

    const calculateTotalDuration = (objects: any[]) => {
        const duration = objects.reduce((totalDuration, obj) => {
            const startTime = dayjs(obj.device_start_time);
            const endTime = dayjs(obj.device_end_time);

            if (startTime.isValid() && endTime.isValid()) {
                const duration = endTime.diff(startTime);
                return totalDuration.add(duration, 'milliseconds');
            }
            return totalDuration;
        }, dayjs.duration(0, 'milliseconds'));
        return dayjs.duration(duration, 'milliseconds').asHours();
    }

    const calculateTotalDistance = (routes: Route[]) => {
        const distance = routes.reduce((total_distance, route) => {
            return route.approximate_distance ? total_distance + route.approximate_distance / 1000 : total_distance;
        }, 0);
        return distance.toFixed(2);
    }

    const [open_project_assign_menu, setOpenProjectAssignMenu] = useState(false);
    const [anchorEl_project_assign_menu, setAnchorElProjectAssignMenu] = useState<null | HTMLElement>(null);
    const handleCloseAssignProjectMenu = () => {
        setAnchorElProjectAssignMenu(null);
        setOpenProjectAssignMenu(false);
    };

    const handleClickAssignProjects = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorElProjectAssignMenu(event.currentTarget);
        if (open_project_assign_menu == false) {
            setOpenProjectAssignMenu(true);
        }
    };

    //Auto scrolling
    //TODO: Fix autoscrolling
    // const tableContainerRef = useRef<any>(null);
    // const isDependenciesStable = useRef(false);

    // useEffect(() => {
    //     isDependenciesStable.current = false; // Mark dependencies as changing
    //     const selectedRoute = filter_state.select_filter.selected_route;
    //     const selectedWork = filter_state.select_filter.selected_work;
    //     let selector = '';
    //     if (selectedRoute) {
    //         const routeIds = props.routes.map((route) => route.id);
    //         if (routeIds.includes(selectedRoute.id)) {
    //             selector = `#o-Route-${selectedRoute.id}`;
    //         }
    //     } else if (selectedWork) {
    //         const workIds = props.works.map((work) => work.id);
    //         if (workIds.includes(selectedWork.id)) {
    //             selector = `#o-Route-${selectedWork.id}`;
    //         }
    //     }
    //     if (selector) {
    //         if (tableContainerRef.current) {
    //             const container = tableContainerRef.current;
    //             const scrollRowIntoView = () => {
    //                 const rowElement = container.querySelector(selector);
    //                 if (rowElement && isDependenciesStable) {
    //                     const offsetTop = rowElement.offsetTop;
    //                     const containerTop = container.scrollTop;
    //                     const containerBottom = containerTop + container.clientHeight;
    //                     const rowHeight = rowElement.clientHeight;

    //                     // Calculate the heights of the header and footer elements. Adjust these values accordingly.
    //                     const headerHeight = rowHeight;
    //                     const footerHeight = rowHeight;

    //                     // Calculate the container's visible height, accounting for header and footer.
    //                     const containerVisibleHeight = container.clientHeight - (headerHeight + footerHeight);

    //                     if (offsetTop < containerTop + headerHeight) {
    //                         // Scroll up to show the row at the top, considering the header height.
    //                         container.scrollTop = offsetTop - headerHeight;
    //                     } else if (offsetTop + rowHeight > containerBottom - footerHeight) {
    //                         // Scroll down to show the row at the bottom, considering the footer height.
    //                         container.scrollTop = offsetTop + rowHeight - containerVisibleHeight + footerHeight;
    //                     }

    //                     // Preserve your console logging lines for debugging purposes.
    //                     // console.log(rowElement);
    //                     // console.log(`offsetTop ${offsetTop}`);
    //                     // console.log(`containerTop ${containerTop}`);
    //                     // console.log(`containerBottom ${containerBottom}`);
    //                     // console.log(`rowHeight ${rowHeight}`);
    //                     // console.log(`container.clientHeight ${container.clientHeight}`);
    //                 } else {
    //                     // If the rowElement is not available, try again after a short delay
    //                     setTimeout(scrollRowIntoView, 100);
    //                 }
    //             };

    //             // Initially call scrollRowIntoView to handle the case where the selected row is available immediately.
    //             scrollRowIntoView();
    //         }
    //     }
    // }, [filter_state.select_filter.selected_route, filter_state.select_filter.selected_work]);

    // useEffect(() => {
    //     // This effect runs after the dependencies have changed.
    //     // Mark dependencies as stable after a short delay.
    //     setTimeout(() => {
    //         isDependenciesStable.current = true;
    //     }, 500); // Adjust the delay as needed.
    // }, [filter_state.select_filter.selected_route, filter_state.select_filter.selected_work]);


    return (
        <Paper
            // ref={tableContainerRef}
            sx={{ height: '100%', width: '100%', overflow: 'auto' }}
        >
            <Table
                stickyHeader={true}
                size={'small'}
                sx={{ height: '100%', width: '100%' }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell padding='checkbox'>
                            <Checkbox
                                color='primary'
                                indeterminate={
                                    (filter_state.select_filter.selected_routes.length > 0 || filter_state.select_filter.selected_works.length > 0) &&
                                    (filter_state.select_filter.selected_routes.length + filter_state.select_filter.selected_works.length) < objects.length
                                }
                                checked={objects.length > 0 && (filter_state.select_filter.selected_routes.length + filter_state.select_filter.selected_works.length) === objects.length}
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                        <TableCell sx={{ px: 0 }}>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Item
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Start
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }} align='right'>
                            <Typography variant='caption' fontWeight={'bold'} noWrap>
                                Duration [h:m:s]
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0.5, pr: 1.5 }} align='right'>
                            <Typography variant='caption' fontWeight={'bold'} noWrap>
                                Distance [km]
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0 }}>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Project
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ px: 0, m: 0 }}>
                            {/* Linked */}
                        </TableCell>
                        <TableCell sx={{ px: 0, m: 0 }} >
                            {/* Add note */}
                        </TableCell>
                        <TableCell sx={{ px: 0, m: 0 }} >
                            {/* Ignored */}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        objects.length === 0 ?
                            (
                                <TableRow key='spacer'>
                                    <TableCell colSpan={6} align='center'>
                                        Empty
                                    </TableCell>
                                </TableRow>
                            )
                            :
                            (
                                [
                                    ...objects.map((o) => {
                                        return (
                                            <AssetInfoTableRow
                                                key={`o-${o.type}-${o.content.id}`}
                                                asset={props.asset}
                                                map={props.map}
                                                object={o}
                                                isSelected={isSelected}
                                                handleClickCheckbox={handleClickCheckbox}
                                            />
                                        )
                                    }),
                                    <TableRow key='spacer'>
                                        <TableCell colSpan={9} align='center' height={'100%'}>
                                            {/* empty */}
                                        </TableCell>
                                    </TableRow>
                                ]
                            )
                    }
                </TableBody>
                <TableFooter style={tableStyles.stickyFooter}>
                    <TableRow sx={{ backgroundColor: 'background.paper' }}>
                        <TableCell colSpan={3} sx={{ px: 0.5 }}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'flex-end'}
                                    spacing={1}
                                >
                                    <Tooltip title={`${filter_state.select_filter.selected_routes.length} routes selected`}>
                                        <Chip
                                            label={`${filter_state.select_filter.selected_routes.length} selected`}
                                            size='small'
                                            variant='outlined'
                                            color='primary'
                                            sx={{ fontWeight: 'bold' }}
                                            icon={<RouteIcon />}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`${filter_state.select_filter.selected_works.length} works selected`}>
                                        <Chip
                                            label={`${filter_state.select_filter.selected_works.length} selected`}
                                            size='small'
                                            variant='outlined'
                                            sx={{ fontWeight: 'bold' }}
                                            icon={<ConstructionIcon />}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        </TableCell>
                        <TableCell colSpan={2} sx={{ px: 0.5 }}>
                            <Stack
                                direction={'row'}
                                alignItems={'flex-start'}
                                justifyContent={'flex-start'}
                                spacing={0.5}
                            >
                                <Tooltip title='Total length of routes [km]'>
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        color='secondary'
                                        sx={{ fontWeight: 'bold' }}
                                        icon={<StraightenIcon />}
                                        label={`${(filter_state.select_filter.selected_routes.length > 0 || filter_state.select_filter.selected_works.length > 0) ?
                                            calculateTotalDistance(filter_state.select_filter.selected_routes) :
                                            calculateTotalDistance(props.routes)}km`
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title='Total duration of routes [h:m]'>
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        color='primary'
                                        sx={{ fontWeight: 'bold' }}
                                        icon={<RouteIcon />}
                                        label={
                                            `${(filter_state.select_filter.selected_routes.length > 0 || filter_state.select_filter.selected_works.length > 0) ?
                                                formatDuration(calculateTotalDuration(filter_state.select_filter.selected_routes), false) :
                                                formatDuration(calculateTotalDuration(props.routes), false)}`
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title='Total duration of works [h:m]'>
                                    <Chip
                                        size='small'
                                        variant='outlined'
                                        sx={{ fontWeight: 'bold' }}
                                        icon={<ConstructionIcon />}
                                        label={
                                            `${(filter_state.select_filter.selected_routes.length > 0 || filter_state.select_filter.selected_works.length > 0) ?
                                                formatDuration(calculateTotalDuration(filter_state.select_filter.selected_works), false) :
                                                formatDuration(calculateTotalDuration(props.works), false)}`
                                        }
                                    />
                                </Tooltip>
                            </Stack>
                        </TableCell>
                        <TableCell colSpan={4} sx={{ px: 0 }}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                            >
                                {
                                    (filter_state.select_filter.selected_routes.length > 0 || filter_state.select_filter.selected_works.length > 0) &&
                                    <Tooltip title='Assign selected routes/works'>
                                        <Button
                                            size='small'
                                            variant='contained'
                                            color='primary'
                                            onClick={handleClickAssignProjects}
                                            sx={{ textTransform: 'none' }}
                                            fullWidth
                                        >
                                            <Typography fontSize={'inherit'}>
                                                assign selected
                                            </Typography>
                                        </Button>
                                    </Tooltip>
                                }
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            <Menu
                id="installation-menu"
                anchorEl={anchorEl_project_assign_menu}
                open={open_project_assign_menu}
                onClose={handleCloseAssignProjectMenu}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                MenuListProps={{ sx: { py: 0 } }}
                transitionDuration={0}
            >
                <ProjectAssignMenu
                    asset={props.asset}
                    routes={filter_state.select_filter.selected_routes}
                    works={filter_state.select_filter.selected_works}
                    handleCloseAssignProjectMenu={handleCloseAssignProjectMenu}
                    show_map={false}
                />
            </Menu>
        </Paper >
    );
};
