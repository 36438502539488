import { Asset, Route, Work } from '../../models/interfaces';
import { Box, Button, Paper, Stack, Typography, CircularProgress, Card, Chip, Divider, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import TimeAgo from 'timeago-react';
import { AssetInfoTable } from './asset-info-table/asset-info-table';
import { useSelector } from 'react-redux';
import { RoutesState } from '../../slices/routes';
import { WorksState } from '../../slices/works';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { FilterState } from '../../slices/filter';
import { Map } from 'leaflet';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';


export const AssetInfoEmployee = (props: { map: Map, asset: Asset }) => {
    const theme = useTheme();
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);

    const [asset_routes, setAssetRoutes] = useState<Route[]>([]);
    const [asset_works, setAssetWorks] = useState<Work[]>([]);

    useEffect(() => {
        const filtered_asset_routes = filter_state.route_filter.routes.filter((route) => {
            return route.asset_routes.map(r => r.asset?.id).includes(props.asset.id)
        })
        setAssetRoutes(filtered_asset_routes);
        const filtered_asset_works = filter_state.work_filter.works.filter((work) => {
            return work.asset_works.map(w => w.asset?.id).includes(props.asset.id)
        })
        setAssetWorks(filtered_asset_works);
    }, [filter_state.route_filter, filter_state.work_filter, props.asset, filter_state.show_ignored_filter])

    const LoadAssetData = () => {
        //blank for now
    }

    const batteryIconChooser = (battery: number) => {
        if (battery <= 30) {
            return <Battery0BarIcon fontSize='small' sx={{ color: 'red' }} />;
        }
        if (battery <= 40) {
            return <Battery1BarIcon fontSize='small' sx={{ color: 'red' }} />;
        }
        if (battery <= 50) {
            return <Battery2BarIcon fontSize='small' sx={{ color: 'orange' }} />;
        }
        if (battery <= 60) {
            return <Battery3BarIcon fontSize='small' sx={{ color: 'orange' }} />;
        }
        if (battery <= 70) {
            return <Battery4BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 80) {
            return <Battery5BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 90) {
            return <Battery6BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 100) {
            return <BatteryFullIcon fontSize='small' sx={{}} />;
        }
        return <Battery0BarIcon fontSize='small' sx={{}} />;
    }

    const battery = props.asset.parsed_custom_fields?.find((i: any) => i.name == 'battery')?.value.toFixed(0);

    return (
        <Paper
            sx={{ height: '100%', width: '100%' }}
        >
            <Stack
                direction='column'
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                sx={{ height: '100%', width: '100%' }}
                divider={<Divider orientation='horizontal' flexItem />}
            >
                <Paper
                    sx={{ width: '100%', bgcolor: theme.palette.mode === 'light' ? 'ghostwhite' : 'black' }}
                >
                    <Stack
                        direction={'row'}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                        sx={{ px: 2, pt: 1 }}
                    >
                        <Stack
                            direction={'column'}
                            alignItems={'flex-start'}
                            justifyContent={'space-between'}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'flex-start'}
                                justifyContent={'flex-start'}
                            >
                                <Stack
                                    direction={'column'}
                                    alignItems={'flex-start'}
                                    justifyContent={'flex-start'}
                                >
                                    <Typography variant='h6' fontWeight={'bold'}>
                                        {props.asset.name}
                                        {/* {!done && <CircularProgress size='1.5rem' sx={{ ml: 2, mt: 1 }} />} */}
                                    </Typography>
                                    <Typography variant='caption'>
                                        Last seen: {
                                            props.asset.device_update_timestamp ?
                                                dayjs(props.asset.device_update_timestamp).isAfter(dayjs()) ? 'just now' :
                                                    <TimeAgo datetime={props.asset.device_update_timestamp} locale='en_short' /> : 'unknown'
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack
                            direction={'column'}
                            alignItems={'flex-start'}
                            justifyContent={'flex-start'}
                            spacing={1}
                            sx={{ pt: 1 }}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                                spacing={1}
                            >
                                <Tooltip title={`This asset has ${asset_routes.length} routes in the selected datetime range`}>
                                    <Chip
                                        label={asset_routes.length}
                                        size='small'
                                        variant='filled'
                                        color='primary'
                                        sx={{ fontWeight: 'bold' }}
                                        icon={<RouteIcon />}
                                    />
                                </Tooltip>
                                <Tooltip title={`This asset has ${asset_routes.length} works in the selected datetime range`}>
                                    <Chip
                                        label={asset_works.length}
                                        size='small'
                                        variant='outlined'
                                        sx={{ bgcolor: 'lightgray', fontWeight: 'bold' }}
                                        icon={<ConstructionIcon />}
                                    />
                                </Tooltip>
                            </Stack>
                        </Stack>

                        {
                            <Stack
                                direction={'column'}
                                alignItems={'flex-end'}
                                justifyContent={'flex-start'}
                            >
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'flex-start'}
                                >
                                    <Stack
                                        direction={'row'}
                                        alignItems={'flex-end'}
                                        justifyContent={'flex-end'}
                                    >
                                        {battery ? batteryIconChooser(battery) : <BatteryUnknownIcon />}
                                        <Typography variant='caption' sx={{ fontWeight: 'bold', pl: '2px', pt: '1px', pr: 1 }}>
                                            {battery ? battery + '%' : ''}
                                        </Typography>
                                    </Stack>
                                    <Chip
                                        size='small'
                                        label={
                                            props.asset.coordinate_type == 'Navigating' ? 'Navigating' :
                                                props.asset.coordinate_type == 'Start' ? 'Navigating' :
                                                    props.asset.coordinate_type == 'Stopped' ? 'Stopped' :
                                                        props.asset.coordinate_type == 'End' ? 'Stopped' :
                                                            'default'
                                        }
                                        color={
                                            props.asset.coordinate_type == 'Navigating' ? 'success' :
                                                props.asset.coordinate_type == 'Start' ? 'success' :
                                                    props.asset.coordinate_type == 'Stopped' ? 'error' :
                                                        props.asset.coordinate_type == 'End' ? 'error' :
                                                            'default'
                                        }
                                    />
                                    {/* <IconButton color='inherit' onClick={() => LoadAssetData()}>
                                        <RefreshIcon />
                                    </IconButton> */}

                                </Stack>
                                <Typography variant='caption'>
                                    {`asset ${props.asset.id} tag ${props.asset.tag_id} ${props.asset.hardware_id}`}
                                </Typography>
                            </Stack>
                        }
                    </Stack>
                </Paper>
                <AssetInfoTable map={props.map} asset={props.asset} routes={asset_routes} works={asset_works} />
            </Stack>
        </Paper>
    );
};
