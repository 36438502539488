import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './store';
import { AssetsState, retrieveAssets } from './slices/assets';
import { TagsState, retrieveTags, retrieveTagsById } from './slices/tags';
import { RoutesState, retrieveRoutesAfterId, retrieveRoutesBetweenDateTime } from './slices/routes';
import { WorksState, retrieveWorksAfterDateTime, retrieveWorksAfterId, retrieveWorksAfterIdBeforeDateTime, retrieveWorksById, retrieveWorksStartBetweenDateTime } from './slices/works';
import { FilterState, filterRoutes, filterWorks, removeSelectFilterSelectedRoutes, removeSelectFilterSelectedWorks, setAssetTypeFilters, setLoadStateAreasLoad, setLoadStateAssetsLoad, setLoadStateProjectsLoad, setLoadStateRatesLoad, setLoadStateRoutesLoad, setLoadStateSitesLoad, setLoadStateTagsLoad, setLoadStateWorksLoad } from './slices/filter';
import { ProjectsState, retrieveProjectFirstAndLastBetweenDateTime, retrieveProjects, retrieveProjectsAfterServerTimestampUpdated, retrieveProjectsBetweenDateTime, selectAllProjects } from './slices/projects';
import { useEffect, useRef } from 'react';
import { Route, Work } from './models/interfaces';
import getProjectFirstBetweenDateTime from './services/project.service';
import dayjs from 'dayjs';
import { SitesState, retrieveSites } from './slices/sites';
import { AreasState, retrieveAreas } from './slices/areas';
import { RatesState, retrieveRates } from './slices/rates';

export const Loader = () => {
    const dispatch = useDispatch<AppDispatch>();
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const tags_state = useSelector((state: { tags: TagsState }) => state.tags);
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const projects = useSelector(selectAllProjects);
    const projects_state = useSelector((state: { projects: ProjectsState }) => state.projects);
    const sites_state = useSelector((state: { sites: SitesState }) => state.sites);
    const areas_state = useSelector((state: { areas: AreasState }) => state.areas);
    const rates_state = useSelector((state: { rates: RatesState }) => state.rates);


    useEffect(() => {
        const delay = 3000;

        // Load assets immediately
        if (filters_state.load_state.assets_load && assets_state.status === 'idle') {
            console.log('loading assets');
            dispatch(setLoadStateAssetsLoad(false));
            dispatch(retrieveAssets());
        }

        // Load tags immediately
        if (filters_state.load_state.tags_load && tags_state.status === 'idle') {
            console.log('loading tags');
            dispatch(setLoadStateTagsLoad(false));
            dispatch(retrieveTags());
        }

        // Delay loading other data
        setTimeout(() => {
            if (filters_state.load_state.routes_load && routes_state.status === 'idle') {
                console.log('loading routes');
                dispatch(setLoadStateRoutesLoad(false));
                dispatch(retrieveRoutesBetweenDateTime({ start: filters_state.route_filter.start?.datetime_string, end: filters_state.route_filter.end?.datetime_string }));
            }
            if (filters_state.load_state.works_load && works_state.status === 'idle') {
                console.log('loading works');
                dispatch(setLoadStateWorksLoad(false));
                dispatch(retrieveWorksAfterDateTime({ start: filters_state.route_filter.start?.datetime_string }));
            }
            if (filters_state.load_state.projects_load && projects_state.status === 'idle') {
                console.log('loading projects');
                dispatch(setLoadStateProjectsLoad(false));
                getProjects();
            }
            if (filters_state.load_state.sites_load && sites_state.status === 'idle') {
                console.log('loading sites');
                dispatch(setLoadStateSitesLoad(false));
                dispatch(retrieveSites());
            }
            if (filters_state.load_state.areas_load && areas_state.status === 'idle') {
                console.log('loading areas');
                dispatch(setLoadStateAreasLoad(false));
                dispatch(retrieveAreas());
            }
            if (filters_state.load_state.rates_load && rates_state.status === 'idle') {
                console.log('loading rates');
                dispatch(setLoadStateRatesLoad(false));
                dispatch(retrieveRates());
            }
        }, delay);
    }, []);


    useEffect(() => {
        getRoutes();
        getWorks();
    }, [filters_state.route_filter.start, filters_state.route_filter.end, filters_state.work_filter.start, filters_state.work_filter.end])

    const getRoutes = () => {
        const filter_start = dayjs(filters_state.route_filter.start?.datetime_string);
        const filter_end = dayjs(filters_state.route_filter.end?.datetime_string);
        dispatch(retrieveRoutesBetweenDateTime({ start: filter_start.toISOString(), end: filter_end.toISOString() }));
    }

    const getWorks = () => {
        const filter_start = dayjs(filters_state.work_filter.start?.datetime_string);
        const filter_end = dayjs(filters_state.work_filter.end?.datetime_string);
        dispatch(retrieveWorksStartBetweenDateTime({ start: filter_start.toISOString(), end: filter_end.toISOString() }));
    }

    useEffect(() => {
        if (!filters_state.load_state.projects_load) {
            dispatch(setLoadStateProjectsLoad(false));
            getProjects();
        }
    }, [filters_state.project_filter.start, filters_state.project_filter.end])

    const getProjects = () => {
        console.log('get projects');
        const filter_start = filters_state.project_filter.start?.datetime_string;
        const filter_end = filters_state.project_filter.end?.datetime_string
        // const months = dayjs.duration(filter_end ? dayjs(filter_end).diff(dayjs(filter_start)) : 0).months();
        // for (let i = months - 1; i >= 0; i--) {
        //     const subrange_start = dayjs(filter_start).add(i, 'month').startOf('month').toISOString();
        //     const subrange_end = dayjs(filter_start).add(i + 1, 'month').startOf('month').toISOString();
        //     dispatch(retrieveProjectFirstAndLastBetweenDateTime({ start: subrange_start, end: subrange_end }));
        //     dispatch(retrieveProjectsBetweenDateTime({ start: subrange_start, end: subrange_end }));
        // }
        dispatch(retrieveProjectFirstAndLastBetweenDateTime({ start: filter_start, end: filter_end }));
        dispatch(retrieveProjectsBetweenDateTime({ start: filter_start, end: filter_end }));
    }

    let intervalRef = useRef<any>();
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            dispatch(retrieveAssets());
            const navigating_tag_ids = assets_state.assets.filter((asset) => asset.coordinate_type == 'Navigating').map((asset) => asset.tag_id);
            dispatch(retrieveTagsById({ ids: [...navigating_tag_ids] }));
            if (routes_state.routes !== undefined && routes_state.routes.length >= 1) {
                const last_route_ids: number[] = [];
                assets_state.assets.forEach((asset) => {
                    //Find last route and last work of each asset
                    let last_route: Route | undefined = undefined;
                    let last_work: Work | undefined = undefined;
                    const routes = routes_state.routes;
                    const works = works_state.works;
                    for (let i = 0; i < routes.length; i++) {
                        const route = routes[i];
                        if (route?.tag_id !== undefined && route.tag_id == asset.id) {
                            last_route = route;
                            break;
                        }
                    }
                    for (let i = 0; i < works.length; i++) {
                        const work = works[i];
                        if (work?.tag_id !== undefined && work.tag_id == asset.id) {
                            last_work = work;
                            break;
                        }
                    }
                    if (last_route && last_work) {
                        if (last_work.start_route != last_route.id) { //check if we miss the start route of the last work
                            last_route_ids.push(last_route.id);
                        }
                    }
                });
                if (last_route_ids.length > 0) {
                    let last_route_id = Math.min(...last_route_ids);
                    if (last_route_id >= 1) {
                        last_route_id = last_route_id - 1;
                    }
                    console.log('getting missing routes');
                    dispatch(retrieveRoutesAfterId({ id: last_route_id }));
                }
                else {
                    const last_route = routes_state?.routes?.reduce((a, b) => a.id > b.id ? a : b);
                    dispatch(retrieveRoutesAfterId({ id: last_route.id }));
                }
            }
            if (works_state.works !== undefined && works_state.works.length >= 1) {
                const last_work = works_state?.works?.reduce((a, b) => a.id > b.id ? a : b);
                dispatch(retrieveWorksAfterIdBeforeDateTime({ id: last_work.id, datetime: filters_state.route_filter.end.datetime_string })); //Get new works
                dispatch(retrieveWorksById({ ids: [...works_state.works.filter((work) => work.device_end_time == null)].map((work) => { return work.id }) })); //Get unfinished works
            }
        }, 20000); //Refresh interval
        return () => clearInterval(intervalRef.current);
    }, [routes_state, works_state, assets_state, tags_state]);

    let project_sync_intervalRef = useRef<any>();
    useEffect(() => {
        project_sync_intervalRef.current = setInterval(() => {
            if (projects && projects.length && projects.length > 0) {
                const project = [...projects].sort((a, b) => dayjs(b.server_timestamp_updated).unix() - dayjs(a.server_timestamp_updated).unix())[0];
                if (project) {
                    dispatch(retrieveProjectsAfterServerTimestampUpdated({ server_timestamp_updated: project.server_timestamp_updated })).then((result) => {
                        const payload: any = result.payload;
                        if (payload && payload.length && payload.length > 0) {
                            const synced: number = payload.length;
                            console.log(`synced ${synced} projects`);
                        }
                    });
                }
            }
        }, 20000);
        return () => clearInterval(project_sync_intervalRef.current);
    }, [routes_state, works_state, assets_state, tags_state]);


    //Filtering

    useEffect(() => {
        if (routes_state.routes !== undefined) {
            dispatch(filterRoutes(routes_state.routes));
        }
        if (works_state.works !== undefined) {
            dispatch(filterWorks(works_state.works));
        }
        if (!filters_state.show_ignored_filter) {
            const routes_to_unselect: Route[] = routes_state.routes.filter((route) => route.is_ignored);
            const works_to_unselect: Work[] = works_state.works.filter((work) => work.is_ignored);
            if (routes_to_unselect.length > 0) {
                dispatch(removeSelectFilterSelectedRoutes(routes_to_unselect));
            }
            if (works_to_unselect.length > 0) {
                dispatch(removeSelectFilterSelectedWorks(works_to_unselect));
            }
        }
    },
        [
            routes_state.routes,
            works_state.works,
            filters_state.route_filter.start,
            filters_state.route_filter.end,
            filters_state.work_filter.start,
            filters_state.work_filter.end,
            filters_state.show_ignored_filter,
            filters_state.show_works_in_progress_filter
        ]
    );

    // Asset type filters
    useEffect(() => {
        if (assets_state.status == 'succeeded') {
            const asset_types = assets_state.assets
                ?.map((item) => item.asset_type)
                ?.filter((value, index, self) => self.indexOf(value) === index);
            const asset_type_filters = asset_types.map((asset_type, index) => {
                return {
                    id: index,
                    asset_type: asset_type,
                    enabled: (asset_type.toLowerCase() === 'vehicle') ? true : false
                }
            });
            dispatch(setAssetTypeFilters(asset_type_filters));
        }
    }, [assets_state.assets.length]);


    return (
        <>
        </>
    )
}