import { useState, useEffect } from 'react';
import { ButtonGroup } from '@mui/material';
import { useSelector } from 'react-redux';
import { AssetsState } from '../../slices/assets';
import { FilterState, setAssetTypeFilters } from '../../slices/filter';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { addAssetTypeFilter, enableAssetTypeFilter, removeAssetTypeFilters } from '../../slices/filter';
import { AssetTypeFilterButton } from './asset-type-filter-button';

export const AssetTypeFilter = () => {
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);

    // const dispatch = useDispatch<AppDispatch>();
    // useEffect(() => {
    //     if (assets_state.status == 'succeeded') {
    //         const asset_types = assets_state.assets
    //             ?.map((item) => item.asset_type)
    //             ?.filter((value, index, self) => self.indexOf(value) === index);
    //         const asset_type_filters = asset_types.map((asset_type, index) => {
    //             return {
    //                 id: index,
    //                 asset_type: asset_type,
    //                 enabled: (asset_type.toLowerCase() === 'employee' || asset_type.toLowerCase().includes('unused')) ? false : true
    //             }
    //         });
    //         dispatch(setAssetTypeFilters(asset_type_filters));
    //     }
    // }, [assets_state.assets.length]);

    return (
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
            {filter_state?.asset_type_filters?.filter(asset_type_filter => !asset_type_filter.asset_type.toLowerCase().includes('unused')).map((asset_type_filters) => {
                return <AssetTypeFilterButton asset_type_filter={asset_type_filters} key={`asset-type-filter-${asset_type_filters.id}`} />
            })}
        </ButtonGroup>
    );
}
