import { Project, SubProject } from '../../../models/interfaces';
import { Box, Paper, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { ProjectSummaryOverviewTableRow } from './project-summary-overview-table-row';
import { useEffect, useState } from 'react';
import { calculateTotalCost, calculateTotalCostAdjusted, calculateTotalDistance, calculateTotalDistanceCost, calculateTotalDuration, calculateTotalDurationCost, calculateTotalDurationCostAdjusted, calculateTotalDurationHours, calculateTotalDurationHoursAdjusted, calculateTotalResourceCost, calculateTotalRouteDuration, calculateTotalRouteDurationHours, calculateTotalRouteDurationHoursAdjusted, calculateTotalWorkDuration, calculateTotalWorkDurationHours, calculateTotalWorkDurationHoursAdjusted } from '../project-utils';

const ODD_OPACITY = 0.2;
const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
};

export const ProjectSummaryOverviewTable = (props: { project: Project, sub_projects: SubProject[], summary_objects: any, sub_project_colors: Record<string, string>, }) => {
    const [checked, setChecked] = useState(false);
    const [sorted_sub_project, setSortedSubProjects] = useState<SubProject[]>(props.sub_projects);

    useEffect(() => {
        setSortedSubProjects([...props.sub_projects].sort((a, b) => a.name.localeCompare(b.name)));
    }, [props.project, props.sub_projects, props.summary_objects])

    return (
        <Paper
            sx={{ height: '100%', width: '100%', overflow: 'auto', m: 0.5 }}>
            <Table
                stickyHeader={true}
                size={'small'}
                sx={{ height: '100%', width: '100%' }}
                onClick={(e) => e.stopPropagation()}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Sub project
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Items
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Distance (km)
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Distance cost
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Travel hours
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Work hours
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Total hours
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Time cost
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Resource cost
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Total cost
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.summary_objects.length === 0 ?
                            (
                                <TableRow key='spacer'>
                                    <TableCell colSpan={12} align='center'>
                                        Empty
                                    </TableCell>
                                </TableRow>
                            )
                            :
                            (
                                [
                                    sorted_sub_project.map((sub_project, index) =>
                                        <ProjectSummaryOverviewTableRow
                                            key={sub_project.id}
                                            index={index}
                                            sub_project={sub_project}
                                            summary_objects={props.summary_objects}
                                            remove={checked}
                                            sub_project_colors={props.sub_project_colors}
                                        />
                                    ),
                                    <TableRow key='spacer'>
                                        <TableCell colSpan={12} align='center' height={'100%'}>
                                            {/* empty */}
                                        </TableCell>
                                    </TableRow>
                                ]
                            )
                    }
                </TableBody>
                <TableFooter style={tableStyles.stickyFooter}>
                    <TableRow sx={{ backgroundColor: 'background.paper' }}>
                        <TableCell colSpan={2}>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`Total items: ${props.summary_objects.length}`}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`${(calculateTotalDistance(props.summary_objects) / 1000.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`R ${(calculateTotalDistanceCost(props.summary_objects) / 100.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {calculateTotalRouteDurationHoursAdjusted(props.summary_objects, props.sub_projects).toFixed(2)}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {calculateTotalWorkDurationHoursAdjusted(props.summary_objects, props.sub_projects).toFixed(2)}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {calculateTotalDurationHoursAdjusted(props.summary_objects, props.sub_projects).toFixed(2)}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`R ${(calculateTotalDurationCostAdjusted(props.summary_objects, props.sub_projects) / 100.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`R ${(calculateTotalResourceCost(props.summary_objects, props.sub_projects) / 100.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`R ${(calculateTotalCostAdjusted(props.summary_objects, props.sub_projects) / 100.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
}