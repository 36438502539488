import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsState } from '../../../slices/assets';
import { Box, Card, Chip, Menu, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Badge, Tooltip } from '@mui/material';
import { Asset, Route, Work } from '../../../models/interfaces';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TokenIcon from '@mui/icons-material/Token';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { FilterState, setSelectFilterSelectedAsset, setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks } from '../../../slices/filter';
import dayjs from 'dayjs';
import TimeAgo from 'timeago-react';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import { useState, useEffect } from 'react'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import CropFreeIcon from '@mui/icons-material/CropFree';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AdjustIcon from '@mui/icons-material/Adjust';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import NavigationIcon from '@mui/icons-material/Navigation';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { AppDispatch } from '../../../store';
import { RoutesState } from '../../../slices/routes';
import { WorksState } from '../../../slices/works';
import { calculateTotalWorkDurationHours } from '../../project/project-utils';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AssetInfoEmployee } from '../../asset/asset-info-employee';
import { TimesheetEmployeeInfo } from '../timesheet-employee-info';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const TimesheetEmployeeTableRow = (props: { asset: Asset, setTimesheetEmployeeInfo: any }) => {
    const dispatch = useDispatch<AppDispatch>();
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const [asset_routes, setAssetRoutes] = useState<Route[]>([]);
    const [asset_works, setAssetWorks] = useState<Work[]>([]);
    const [unlinked_routes, setUnlinkedRoutes] = useState<Route[]>([]);
    const [unlinked_works, setUnlinkedWorks] = useState<Work[]>([]);

    useEffect(() => {
        const filtered_asset_routes = filter_state.route_filter.routes.filter((route) => {
            return route.asset_routes.map(r => r.asset?.id).includes(props.asset.id)
        })
        setAssetRoutes(filtered_asset_routes);
        const filtered_asset_works = filter_state.work_filter.works.filter((work) => {
            return work.asset_works.map(w => w.asset?.id).includes(props.asset.id)
        })
        setAssetWorks(filtered_asset_works);
    }, [filter_state.route_filter, filter_state.work_filter, props.asset, routes_state.routes, works_state.works]);

    useEffect(() => {
        const routes_unlinked = asset_routes.filter((route: Route) => { return route.list_of_sub_projects.length === 0 });
        const works_unlinked = asset_works.filter((work: Work) => { return work.list_of_sub_projects.length === 0 });
        setUnlinkedRoutes(routes_unlinked);
        setUnlinkedWorks(works_unlinked);
    }, [asset_routes, asset_works])


    const vehicleIconChooser = (asset: Asset) => {
        if (asset.asset_type === 'Vehicle') {
            return <DirectionsCarIcon
                sx={{}}
                fontSize='small'
                color={
                    asset.coordinate_type === 'Navigating' ? 'success' :
                        asset.coordinate_type === 'Start' ? 'success' :
                            asset.coordinate_type === 'Stopped' ? 'inherit' :
                                asset.coordinate_type === 'End' ? 'inherit' :
                                    'inherit'
                }
            />;
        }
        if (asset.asset_type === 'Employee') {
            return <EngineeringIcon sx={{}} fontSize='small' />;
        }
        if (asset.asset_type === 'Installation') {
            return <TokenIcon sx={{}} fontSize='small' />;
        }
        return <ViewInArIcon sx={{}} fontSize='small' />;
    }

    const isVehicleInactive = (asset: Asset | undefined) => {
        if (asset) {
            return (asset.coordinate_type === 'Navigating' || asset.coordinate_type === 'Start' || asset.coordinate_type === 'End') && //If navigating
                dayjs.duration(dayjs().diff(asset.device_update_timestamp), 'milliseconds').asHours() > 1; //If last seen more than an hour ago
        } else {
            return true;
        }
    }

    const vehicleNavigatingIconChooser = (asset: Asset) => {
        if (asset.coordinate_type === 'Start' || asset.coordinate_type === 'Navigating') {
            return (
                <Tooltip title={
                    `This vehicle asset is currently navigating ${isVehicleInactive(props.asset) ? `but the last update was more than ${dayjs.duration(-dayjs().diff(asset.device_update_timestamp), 'milliseconds').humanize(true)}` : ''}`
                }
                >

                    <NavigationIcon
                        fontSize='small'
                        color={isVehicleInactive(props.asset) ? 'disabled' : 'success'}
                        sx={{ px: 0.5 }}
                    />
                </Tooltip>
            );
        }
    }

    const lastSeenIconChooser = (asset: Asset) => {
        if (dayjs().diff(dayjs(props.asset.device_update_timestamp), 'minutes') <= 10) {
            return (
                <Tooltip title={'This asset has updated within the last 10 minutes'}               >
                    <MonitorHeartIcon
                        fontSize='small'
                        color={'success'}
                        sx={{ px: 0.5 }}
                    />
                </Tooltip >
            );
        }
    }

    const batteryIconChooser = (battery: number) => {
        if (battery <= 30) {
            return <Battery0BarIcon fontSize='small' />; //sx={{ color: 'red' }}
        }
        if (battery <= 40) {
            return <Battery1BarIcon fontSize='small' />; //sx={{ color: 'red' }}
        }
        if (battery <= 50) {
            return <Battery2BarIcon fontSize='small' />; //sx={{ color: 'orange' }} 
        }
        if (battery <= 60) {
            return <Battery3BarIcon fontSize='small' />; //sx={{ color: 'orange' }} 
        }
        if (battery <= 70) {
            return <Battery4BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 80) {
            return <Battery5BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 90) {
            return <Battery6BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 100) {
            return <BatteryFullIcon fontSize='small' sx={{}} />;
        }
        return <Battery0BarIcon fontSize='small' sx={{}} />;
    }

    const battery = props.asset.parsed_custom_fields?.find((i: any) => i.name === 'battery')?.value.toFixed(0);


    const [open_chart_menu, setOpenChartMenu] = useState(false);
    const [anchorEl_chart_menu, setAnchorElChartMenu] = useState<null | HTMLElement>(null);
    const handleCloseChartMenu = () => {
        setAnchorElChartMenu(null);
        setOpenChartMenu(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (props.asset.asset_type === 'Employee') {
            props.setTimesheetEmployeeInfo(
                <TimesheetEmployeeInfo asset={props.asset} />
            )
        }
        dispatch(setSelectFilterSelectedAsset(props.asset));
    };

    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
    };


    const [total_work_duration, setTotalWorkDuration] = useState(0);

    useEffect(() => {
        setTotalWorkDuration(calculateTotalWorkDuration(asset_works));
    }, [asset_works]);

    const calculateTotalWorkDuration = (works: Work[]) => {
        if (works) {
            const total = works.reduce((hours, work) => {
                if (work.device_duration) {
                    return hours + (work.device_duration / 60 / 60);
                } else {
                    return hours;
                }
            }, 0)
            return total;
        } else {
            return 0;
        }
    }

    return (
        <TableRow
            hover
        >
            <TableCell onClick={handleClick} onContextMenu={handleRightClick}>
                <Stack
                    direction={'row'}
                    alignItems='center'
                    justifyContent={'flex-start'}
                    spacing={1}
                >
                    {vehicleIconChooser(props.asset)}
                    <Typography fontWeight={'bold'}>
                        {props.asset.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell onClick={handleClick} onContextMenu={handleRightClick}>
                <Stack
                    direction={'row'}
                    alignItems={'flex-end'}
                    justifyContent={'flex-end'}
                >
                    {vehicleNavigatingIconChooser(props.asset)}
                    {lastSeenIconChooser(props.asset)}
                </Stack>
            </TableCell>
            <TableCell onClick={handleClick} onContextMenu={handleRightClick} sx={{ m: 0, p: 0 }}>
                {
                    (props.asset.asset_type === 'Vehicle' || props.asset.asset_type === 'Employee') && (asset_routes.length > 0 || asset_works.length > 0) &&
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        spacing={1}
                    >
                        {/* <Chip
                            label={asset_routes.length}
                            size='small'
                            variant='filled'
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                            icon={<RouteIcon />}
                        /> */}
                        {
                            <Tooltip title={`This asset has ${asset_works.length} works for a total of ${total_work_duration.toFixed(2)} hours`}>
                                <Chip
                                    // label={asset_works.length}
                                    label={`${total_work_duration.toFixed(2)}h`}
                                    size='small'
                                    variant='outlined'
                                    // sx={{ bgcolor: 'lightgray', fontWeight: 'bold' }}
                                    icon={<ConstructionIcon />}
                                />
                            </Tooltip>
                        }
                        {
                            (unlinked_routes.length > 0 || unlinked_works.length > 0) &&
                            <Tooltip title={'This asset has unassigned items in the selected time range'}>
                                <Chip
                                    label={unlinked_routes?.length + unlinked_works?.length}
                                    size='small'
                                    variant='outlined'
                                    color='error'
                                    // sx={{ fontWeight: 'bold' }}
                                    icon={<LinkOffIcon />}
                                />
                            </Tooltip>
                        }
                    </Stack>
                }
            </TableCell>
            <TableCell onClick={handleClick} onContextMenu={handleRightClick} sx={{ m: 0, p: 1 }}>
                <Stack
                    direction={'row'}
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                >
                    <Typography variant='caption'>
                        {
                            props.asset.device_update_timestamp ?
                                dayjs(props.asset.device_update_timestamp).isAfter(dayjs()) ? 'just now' :
                                    <TimeAgo datetime={props.asset.device_update_timestamp} locale='en_short' /> : 'unknown'
                        }
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell onClick={handleClick} onContextMenu={handleRightClick} sx={{ m: 0, p: 0 }}>
                <Stack
                    direction={'row'}
                    alignItems={'flex-end'}
                    justifyContent={'flex-end'}
                >
                    <Typography variant='caption' sx={{ fontWeight: 'bold', pl: '2px', pt: '1px' }}>
                        {battery ? battery + '%' : ''}
                    </Typography>
                    {battery ? batteryIconChooser(battery) : <BatteryUnknownIcon />}
                </Stack>
            </TableCell>
        </TableRow>
    );
};