import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useRef, useState } from 'react';
import { UserState } from '../slices/user';
import { useSelector } from 'react-redux';
import { Map } from 'leaflet';
import { AppBar, Box, Button, Card, Divider, Menu, Paper, Toolbar, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setCenter, setZoom } from '../slices/map';
import { AppDispatch } from '../store';
import { AssetsState } from '../slices/assets';
import { retrieveAssets } from '../slices/assets';
import { RoutesState, retrieveRoutesAfterId } from '../slices/routes';
import { retrieveRoutes, retrieveRoutesBetweenDateTime, retrieveRoutesAfterDateTime } from '../slices/routes';
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { AssetTypeFilter } from '../components/asset/asset-type-filter';
import { addAssetTypeFilter, filterRoutes, filterWorks, removeSelectFilterSelectedRoutes, removeSelectFilterSelectedWorks } from '../slices/filter';
import { FilterState } from '../slices/filter';
import dayjs, { Dayjs } from 'dayjs';
import { AssetInfo } from '../components/asset/asset-info';
import { AssetTable } from '../components/asset/asset-table/asset-table';
import { WorksState, retrieveWorksById } from '../slices/works';
import { retrieveWorksBetweenDateTime, retrieveWorksAfterId, retrieveWorksAfterDateTime } from '../slices/works';
import { AssetSearchFilter } from '../components/asset/asset-search-filter';
import { ProjectsState, retrieveProjects, retrieveProjectsBetweenDateTime, selectAllProjects } from '../slices/projects';
import { TagsState, retrieveTags, retrieveTagsById } from '../slices/tags';
import { Asset, Route, Work } from '../models/interfaces';
import { AssetRouteWorkFilter } from '../components/asset/asset-routework-filter';
import { AssetInfoInstallation } from '../components/asset/asset-info-installation';
import { Loader } from '../loader';
import MapSites from '../components/map/map-sites';
import Chart from 'react-google-charts';
import { ProjectAssignMenu } from '../components/project/project-assign-menu/project-assign-menu';
import { TimesheetAddWork } from '../components/timesheet/timesheet-add-work';
import { TimesheetEmployeeTableRow } from '../components/timesheet/timesheet-employee-table/timesheet-employee-table-row';
import { TimesheetEmployeeTable } from '../components/timesheet/timesheet-employee-table/timesheet-employee-table';
import { TimesheetEmployeeInfo } from '../components/timesheet/timesheet-employee-info';



export const Timesheets = () => {
    const dispatch = useDispatch<AppDispatch>();
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const [timeline_data, setTimelineData] = useState<any>();
    const [selected_asset, setSelectedAsset] = useState<Asset | undefined>();
    const [selected_route, setSelectedRoute] = useState<Route>();
    const [selected_work, setSelectedWork] = useState<Work>();
    const [mouse_position, setMousePosition] = useState<{ x: number; y: number } | null>(null);
    const [project_assign_menu_position, setProjectAssignMenuPosition] = useState<{ x: number; y: number } | null>(null);
    const [open_project_assign_menu, setOpenProjectAssignMenu] = useState(false);
    const [anchorEl_project_assign_menu, setAnchorElProjectAssignMenu] = useState<null | HTMLElement>(null);
    const [add_work_start_time, setAddWorkStartTime] = useState<Dayjs | undefined>();
    const [add_work_end_time, setAddWorkEndTime] = useState<Dayjs | undefined>();
    const [add_work_asset, setAddWorkAsset] = useState<Asset | undefined>();
    const [open_add_work, setOpenAddWork] = useState(false);
    const [timesheet_employee_info, setTimesheetEmployeeInfo] = useState<any>(<TimesheetEmployeeInfo asset={undefined} />);

    const prepareTimelineDataWithGaps = () => {
        const columns = [
            { type: 'string', id: 'Employee' },
            { type: 'string', id: 'Project' },
            { type: 'string', role: 'style' },
            { type: 'date', id: 'Start' },
            { type: 'date', id: 'End' },
            { type: 'string', role: 'data' },
            { type: 'string', role: 'data' },
            { type: 'string', role: 'data' },
            { type: 'string', role: 'data' },
        ];
        const rows: any = [];
        const gap_color = '#7B98B2';
        [...assets_state.assets]?.sort((a, b) => a.name.localeCompare(b.name)).forEach(asset => {
            const asset_objects: any = [];
            const asset_gaps: any = [];
            if (asset.asset_type.toLowerCase() == 'employee') {
                filters_state.work_filter.works.forEach(work => {
                    if (filters_state.work_filter.enabled &&
                        work.asset_works.find(aw => aw.asset_id == asset.id)) {
                        const work_color = work.list_of_sub_projects.length
                            ? (work.is_ignored ? '#1a4a8c' : '#2e76d0') // Darker blue vs vibrant blue
                            : (work.is_ignored ? '#0e2742' : '#173c69'); // Very dark blue vs dark blue
                        asset_objects.push({ ...work, object_type: 'work' });
                        rows.push([
                            `${asset.name}`,
                            (work.device_end_time
                                ? work.list_of_sub_projects.length
                                    ? work.list_of_sub_projects.map(subproject_id => `KE${subproject_id}`).join(',')
                                    : `W${work.id}`
                                : `W${work.id} (in progress)`) + (work.is_ignored ? " (ignored)" : ""),
                            work_color,
                            dayjs(work.device_start_time).millisecond(0).toDate(),
                            work.device_end_time ? dayjs(work.device_end_time).millisecond(0).toDate() : dayjs().isAfter(dayjs(work.device_start_time)) ? dayjs().toDate() : dayjs(work.device_start_time).millisecond(0).toDate(),
                            asset,
                            null,
                            work,
                            null
                        ]);
                    }
                });
                filters_state.route_filter.routes.forEach(route => {
                    if (route.device_start_time && route.device_end_time &&
                        filters_state.route_filter.enabled &&
                        route.asset_routes.find(ar => ar.asset_id == asset.id)) {
                        const route_color = route.list_of_sub_projects.length
                            ? (route.is_ignored ? '#1a8c1a' : '#2ed12e') // Darker green vs vibrant green
                            : (route.is_ignored ? '#0e420e' : '#176917'); // Very dark green vs dark green
                        asset_objects.push({ ...route, object_type: 'route' });
                        rows.push([
                            `${asset.name}`,
                            (route.list_of_sub_projects.length
                                ? route.list_of_sub_projects.map(subproject_id => `KE${subproject_id}`).join(',')
                                : `R${route.id}`) + (route.is_ignored ? " (ignored)" : ""),
                            route_color,
                            dayjs(route.device_start_time).millisecond(0).toDate(),
                            dayjs(route.device_end_time).millisecond(0).toDate(),
                            asset,
                            route,
                            null,
                            null
                        ]);
                    }
                });

                asset_objects.sort((a: any, b: any) => dayjs(a.device_start_time).unix() - dayjs(b.device_start_time).unix());
                if (asset_objects.length > 0) {
                    const objects_times = asset_objects.flatMap((ao: any) => [
                        { time: ao.device_start_time, type: 'start' },
                        { time: ao.device_end_time, type: 'end' }
                    ]);
                    objects_times.sort((a: any, b: any) => dayjs(a.time).unix() - dayjs(b.time).unix());
                    // Start of work day
                    let gap_end = dayjs(asset_objects[0].device_start_time);
                    let gap_start = gap_end.set('hour', 7).set('minute', 0).set('second', 0);
                    if (gap_start && gap_end && gap_end.isAfter(gap_start) && gap_end.diff(gap_start, 'minutes') > 0) {
                        asset_gaps.push({
                            start: gap_start,
                            end: gap_end
                        });
                    }
                    // Through work day
                    for (let i = 1; i < objects_times.length; i++) {
                        const time_1 = objects_times[i - 1];
                        const time_2 = objects_times[i];
                        if (time_1.type === 'end' && time_2.type === 'start') {
                            const gap = dayjs(time_2.time).diff(dayjs(time_1.time), 'minutes');
                            if (gap > 0) {
                                asset_gaps.push({
                                    start: dayjs(time_1.time),
                                    end: dayjs(time_2.time)
                                });
                            }
                        }
                    };
                    // End of work day - this logic has too many caveats it has been removed for now.
                    const last_asset_object = asset_objects[asset_objects.length - 1];
                    if (last_asset_object.device_end_time
                        // && dayjs(filters_state.work_filter.end.datetime_string).isBefore(dayjs())
                        && !filters_state.show_ignored_filter
                    ) {
                        gap_start = dayjs(last_asset_object.device_end_time);
                        // gap_end = gap_start.set('hour', 16).set('minute', 0).set('second', 0);
                        if (dayjs(filters_state.work_filter.end.datetime_string).isBefore(dayjs())) {
                            gap_end = gap_start.set('hour', 16).set('minute', 0).set('second', 0);
                        } else {
                            gap_end = dayjs().millisecond(0);
                        }
                        if (gap_start && gap_end && gap_end.isAfter(gap_start) && gap_end.diff(gap_start, 'minutes') > 0) {
                            asset_gaps.push({
                                start: gap_start,
                                end: gap_end
                            });
                        }
                    }

                } else {
                    if (!(asset.name.toLowerCase().includes('old') || asset.name.toLowerCase().includes('employee'))) {
                        const start = dayjs(filters_state.work_filter.start.datetime_string).set('hour', 7).set('minute', 0).set('second', 0).toDate();
                        const end = dayjs(filters_state.work_filter.start.datetime_string).set('hour', 8).set('minute', 0).set('second', 0).toDate();
                        rows.push([
                            `${asset.name}`,
                            'No activity',
                            '#F5F7FA',
                            start,
                            end,
                            asset,
                            null,
                            null,
                            {
                                start: start,
                                end: end
                            }
                        ]);
                    }
                }

                // console.log(`Gaps for asset ${asset.name}:`);
                asset_gaps.forEach((gap: any) => {
                    rows.push([
                        `${asset.name}`,
                        `Unallocated activity`,
                        gap_color,
                        dayjs(gap.start).millisecond(0).toDate(),
                        dayjs(gap.end).millisecond(0).toDate(),
                        asset,
                        null,
                        null,
                        gap
                    ]);
                });

            }
        });

        const data = [columns, ...rows];
        return data;

    };

    useEffect(() => {
        // const data = prepareTimelineData(assets_state.assets, filters_state.work_filter.works, filters_state.route_filter.routes);
        const data = prepareTimelineDataWithGaps();
        setTimelineData(data);
    }, [
        assets_state.assets,
        works_state.works,
        routes_state.routes,
        filters_state.route_filter,
        filters_state.work_filter,
        filters_state.show_ignored_filter
    ]);

    const handleTimelineClick = (chartWrapper: any) => {
        const selection = chartWrapper!.getChart().getSelection();
        if (selection.length === 0) return;
        const row = selection[0].row;
        if (row != null) {
            const selectedRowData = timeline_data[row + 1];
            const asset = selectedRowData[5];
            const route = selectedRowData[6];
            const work = selectedRowData[7];
            const gap = selectedRowData[8];
            setSelectedAsset(asset);
            setTimesheetEmployeeInfo(
                <TimesheetEmployeeInfo asset={asset} />
            );
            if (gap && gap.start && gap.end) {
                setAddWorkStartTime(dayjs(gap.start).second(0));
                setAddWorkEndTime(dayjs(gap.end).second(0));
                setAddWorkAsset(asset);
                setOpenAddWork(true);
            }
            else {
                setProjectAssignMenuPosition({
                    x: 900,
                    y: 200
                })
                setSelectedRoute(route);
                setSelectedWork(work);
                setOpenProjectAssignMenu(true);
            }
        }
    };


    const handleCloseAssignProjectMenu = () => {
        setAnchorElProjectAssignMenu(null);
        setOpenProjectAssignMenu(false);
    };

    return (
        <Grid2
            container
            spacing={0}
            sx={{ height: 'calc(100vh - 48px)', width: '100%' }}
            onContextMenu={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTimesheetEmployeeInfo(
                    <TimesheetEmployeeInfo asset={undefined} />
                );
            }}

        >
            <Grid2
                sx={{ height: '100%' }}
                xs={12} sm={12} md={5} lg={4}
            >
                <Stack
                    sx={{ height: '100%' }}
                    direction={'column'}
                    justifyContent={'space-between'}
                    overflow={'hidden'}
                    divider={<Divider orientation='horizontal' flexItem />}
                >
                    <Paper sx={{ height: '40%', overflow: 'auto', minHeight: '40%' }}>
                        <TimesheetEmployeeTable setTimesheetEmployeeInfo={setTimesheetEmployeeInfo} />
                    </Paper>
                    <Paper sx={{ height: '60%', overflow: 'auto', minHeight: '60%' }}>
                        <AssetInfo asset_info={timesheet_employee_info} />
                    </Paper>
                </Stack>
            </Grid2>
            <Grid2
                sx={{ height: '100%' }}
                xs={12} sm={12} md={7} lg={8}
            >
                <Stack sx={{ height: 'calc(100% - 8px)', width: 'calc(100% - 8px)', overflow: 'none' }}>
                    <Paper sx={{ height: '100%', width: '100%' }}>
                        <Stack
                            direction='column'
                            sx={{ overflow: 'none', height: '100%', width: '100%' }}
                            alignContent={'center'}
                            justifyContent={'center'}
                        >
                            <Paper sx={{ p: 0.5 }}>
                                <Stack
                                    direction={'row'}
                                    alignContent={'center'}
                                    justifyContent={'space-between'}
                                >
                                    <Typography variant='caption' fontWeight={'bold'} sx={{ p: 1 }}>
                                        {
                                            `Timesheets for ${dayjs(filters_state.work_filter.start.datetime_string).format('HH:mm DD/MM/YYYY')} to ${dayjs(filters_state.work_filter.end.datetime_string).format('HH:mm DD/MM/YYYY')}`
                                        }
                                    </Typography>
                                    <Stack
                                        direction={'row'}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        spacing={1}
                                    >
                                        <TimesheetAddWork work_start_time={add_work_start_time} work_end_time={add_work_end_time} work_assset={add_work_asset} open_add_work={open_add_work} setOpenAddWork={setOpenAddWork} />
                                        <AssetRouteWorkFilter />
                                    </Stack>
                                </Stack>
                            </Paper>
                            {
                                assets_state.assets.length > 0 ? (filters_state.work_filter.works.length > 0 || filters_state.route_filter.routes.length > 0) ?
                                    <>
                                        <Chart
                                            chartType="Timeline"
                                            data={timeline_data}
                                            width="100%"
                                            height="100%"
                                            options={{
                                                timeline: {
                                                    showRowLabels: true,
                                                    groupByRowLabel: true,
                                                    colorByRowLAbel: true,
                                                    // singleColor: '#2e76d0'
                                                    barLabelStyle: { fontSize: 16 },
                                                },
                                                alternatingRowStyle: true,
                                                hAxis: {
                                                    format: 'HH:mm',
                                                    // minValue: dayjs(filters_state.work_filter.start.datetime_string).toDate(),
                                                    // maxValue: dayjs(filters_state.work_filter.end.datetime_string).toDate()
                                                },
                                                avoidOverlappingGridLines: false,
                                                // colors: ['#2e76d0'],
                                            }}
                                            chartEvents={[
                                                {
                                                    eventName: "select",
                                                    callback: ({ chartWrapper }) => {
                                                        handleTimelineClick(chartWrapper);
                                                    },
                                                },
                                            ]}
                                        />
                                        <Menu
                                            id='timesheets-project-assign-menu'
                                            anchorReference="anchorPosition"
                                            anchorPosition={
                                                project_assign_menu_position
                                                    ? { top: project_assign_menu_position.y, left: project_assign_menu_position.x }
                                                    : undefined
                                            }
                                            open={open_project_assign_menu}
                                            onClose={handleCloseAssignProjectMenu}
                                            onKeyDown={(e) => e.stopPropagation()}
                                            onClick={(e) => e.stopPropagation()}
                                            MenuListProps={{ sx: { p: 0 } }}
                                            transitionDuration={0}
                                            aria-hidden={false}
                                        >
                                            <ProjectAssignMenu
                                                asset={selected_asset!}
                                                routes={selected_route ? [selected_route] : []}
                                                works={selected_work ? [selected_work] : []}
                                                handleCloseAssignProjectMenu={handleCloseAssignProjectMenu}
                                                show_map={true}
                                            />
                                        </Menu>
                                    </>
                                    :
                                    <Typography sx={{ height: '100%' }}>
                                        No routes or works in the selected timeframe.
                                    </Typography>
                                    :
                                    <Typography sx={{ height: '100%', p: 1 }}>
                                        Loading assets...
                                    </Typography>
                            }
                        </Stack>
                    </Paper>
                </Stack>
            </Grid2 >
        </Grid2 >
    );
};

