import { Asset, Route, Work } from '../../models/interfaces';
import { Box, Button, Paper, Stack, Typography, CircularProgress, Card, Chip, Divider, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import TimeAgo from 'timeago-react';
import { useSelector } from 'react-redux';
import { RoutesState } from '../../slices/routes';
import { WorksState } from '../../slices/works';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import { FilterState } from '../../slices/filter';
import { Map } from 'leaflet';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import { calculateEmployeeAllocatedTravelHours, calculateEmployeeAllocatedWorkHours, calculateEmployeeTravelHours, calculateEmployeeUnallocatedWorkHours, calculateEmployeeUnallocatedTravelHours, calculateEmployeeWorkHours, calculateOfficialWorkHours } from './timesheet-utils';
import Chart from 'react-google-charts';
import { AssetsState } from '../../slices/assets';



export const TimesheetEmployeeInfo = (props: { asset: Asset | undefined }) => {
    const theme = useTheme();
    const filters_state = useSelector((state: { filter: FilterState }) => state.filter);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const works_state = useSelector((state: { works: WorksState }) => state.works);

    const [asset_routes, setAssetRoutes] = useState<Route[]>([]);
    const [asset_works, setAssetWorks] = useState<Work[]>([]);

    useEffect(() => {
        if (props.asset) {
            const filtered_asset_routes = filters_state.route_filter.routes.filter((route) => {
                return route.asset_routes.map(r => r.asset?.id).includes(props.asset!.id)
            })
            setAssetRoutes(filtered_asset_routes);
            const filtered_asset_works = filters_state.work_filter.works.filter((work) => {
                return work.asset_works.map(w => w.asset?.id).includes(props.asset!.id)
            })
            setAssetWorks(filtered_asset_works);
        } else {
            const filtered_asset_routes = [...filters_state.route_filter.routes];
            setAssetRoutes(filtered_asset_routes);
            const filtered_asset_works = [...filters_state.work_filter.works];
            setAssetWorks(filtered_asset_works);
        }

    }, [filters_state.route_filter, filters_state.work_filter, props.asset, filters_state.show_ignored_filter])


    const [official_work_hours, setOfficialWorkHours] = useState<number>(0);
    const [employee_work_hours, setEmployeeWorkHours] = useState<number>(0);
    const [employee_travel_hours, setEmployeeTravelHours] = useState<number>(0);
    const [employee_allocated_work_hours, setEmployeeAllocatedWorkHours] = useState<number>(0);
    const [employee_allocated_travel_hours, setEmployeeAllocatedTravelHours] = useState<number>(0);
    const [employee_unallocated_work_hours, setEmployeeUnallocatedWorkHours] = useState<number>(0);
    const [employee_unallocated_travel_hours, setEmployeeUnallocatedTravelHours] = useState<number>(0);
    const [bar_chart_data_hours, setBarChartDataHours] = useState<any>([]);

    useEffect(() => {
        //const asset_works: Work[] = filters_state.work_filter.works.filter((work: Work) => work.asset_works.map(aw => aw.asset?.id).includes(props.asset?.id));
        //const asset_routes: Route[] = filters_state.route_filter.routes.filter((route: Route) => route.asset_routes.map(ar => ar.asset?.id).includes(props.asset?.id));
        setOfficialWorkHours(calculateOfficialWorkHours(dayjs(filters_state.work_filter.start.datetime_string), dayjs(filters_state.work_filter.end.datetime_string)));
        setEmployeeWorkHours(calculateEmployeeWorkHours(asset_works));
        setEmployeeTravelHours(calculateEmployeeTravelHours(asset_routes));
        setEmployeeAllocatedWorkHours(calculateEmployeeAllocatedWorkHours(asset_works));
        setEmployeeAllocatedTravelHours(calculateEmployeeAllocatedTravelHours(asset_routes));
        setEmployeeUnallocatedWorkHours(calculateEmployeeUnallocatedWorkHours(asset_works));
        setEmployeeUnallocatedTravelHours(calculateEmployeeUnallocatedTravelHours(asset_routes));
    }, [
        props.asset,
        filters_state.route_filter.routes,
        filters_state.route_filter.start,
        filters_state.route_filter.end,
        filters_state.work_filter.works,
        filters_state.work_filter.start,
        filters_state.work_filter.end,
        asset_routes,
        asset_works
    ]);

    useEffect(() => {
        // let unallocated_hours: number = official_work_hours - (employee_allocated_work_hours + employee_allocated_travel_hours);
        // unallocated_hours = unallocated_hours < 0 ? 0 : unallocated_hours;
        const unallocated_hours = employee_unallocated_travel_hours + employee_unallocated_work_hours;
        const chart_data =
            [
                ['',
                    "Official work hours", { role: "annotation", type: "string" },
                    "Work hours", { role: "annotation", type: "string" },
                    "Travel hours", { role: "annotation", type: "string" },
                    "Combined hours", { role: "annotation", type: "string" },
                    "Summed hours", { role: "annotation", type: "string" },
                    "Allocated work hours", { role: "annotation", type: "string" },
                    "Allocated travel hours", { role: "annotation", type: "string" },
                    "Overview", { role: "annotation", type: "string" },
                    "Unallocated", { role: "annotation", type: "string" },
                ],
                ['Worked',
                    undefined, '',
                    employee_work_hours, `${employee_work_hours.toFixed(2)}`,
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                ],
                ['Traveled',
                    undefined, '',
                    undefined, '',
                    employee_travel_hours, `${employee_travel_hours.toFixed(2)}`,
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                ],
                ['Combined',
                    undefined, '',
                    employee_work_hours, `${employee_work_hours.toFixed(2)}`,
                    employee_travel_hours, `${employee_travel_hours.toFixed(2)}`,
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                ],
                ['Summed',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    (employee_work_hours + employee_travel_hours), `${(employee_work_hours + employee_travel_hours).toFixed(2)}`,
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                ],
                // ['Allocated work',
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                //     employee_allocated_work_hours, `${employee_allocated_work_hours.toFixed(2)}`,
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                // ],
                // ['Allocated travel',
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                //     employee_allocated_travel_hours, `${employee_allocated_travel_hours.toFixed(2)}`,
                //     undefined, '',
                //     undefined, '',
                //     undefined, '',
                // ],
                ['Allocation',
                    0, '',
                    0, '',
                    0, '',
                    0, '',
                    0, '',
                    employee_allocated_work_hours, `${employee_allocated_work_hours.toFixed(2)}`,
                    employee_allocated_travel_hours, `${employee_allocated_travel_hours.toFixed(2)}`,
                    // 0, '',
                    0, '',
                    unallocated_hours, `${unallocated_hours ? unallocated_hours.toFixed(2) : ''}`,
                ],
                // ['Unallocated',
                //     0, '',
                //     0, '',
                //     0, '',
                //     0, '',
                //     0, '',
                //     0, '',
                //     0, '',
                //     0, '',
                //     official_work_hours - (employee_allocated_work_hours + employee_allocated_travel_hours), `${(official_work_hours - (employee_allocated_work_hours + employee_allocated_travel_hours)).toFixed(2)}`,
                // ],               
            ]
        if (props.asset) {
            chart_data.push(
                ['Work hours',
                    official_work_hours, `${official_work_hours.toFixed(2)}`,
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                    undefined, '',
                ],
            );
        }
        setBarChartDataHours(chart_data);

    }, [
        props.asset,
        official_work_hours,
        employee_work_hours,
        employee_travel_hours,
        employee_allocated_work_hours,
        employee_allocated_travel_hours,
    ]);

    const batteryIconChooser = (battery: number) => {
        if (battery <= 30) {
            return <Battery0BarIcon fontSize='small' sx={{ color: 'red' }} />;
        }
        if (battery <= 40) {
            return <Battery1BarIcon fontSize='small' sx={{ color: 'red' }} />;
        }
        if (battery <= 50) {
            return <Battery2BarIcon fontSize='small' sx={{ color: 'orange' }} />;
        }
        if (battery <= 60) {
            return <Battery3BarIcon fontSize='small' sx={{ color: 'orange' }} />;
        }
        if (battery <= 70) {
            return <Battery4BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 80) {
            return <Battery5BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 90) {
            return <Battery6BarIcon fontSize='small' sx={{}} />;
        }
        if (battery <= 100) {
            return <BatteryFullIcon fontSize='small' sx={{}} />;
        }
        return <Battery0BarIcon fontSize='small' sx={{}} />;
    }

    const battery = props.asset?.parsed_custom_fields?.find((i: any) => i.name == 'battery')?.value.toFixed(0);

    return (
        <Paper
            sx={{ height: '100%', width: '100%' }}
        >
            <Stack
                direction='column'
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                sx={{ height: '100%', width: '100%' }}
                divider={<Divider orientation='horizontal' flexItem />}
            >
                {
                    props.asset &&
                    <Paper
                        sx={{ width: '100%', bgcolor: theme.palette.mode === 'light' ? 'ghostwhite' : 'black' }}
                    >
                        <Stack
                            direction={'row'}
                            alignItems={'flex-start'}
                            justifyContent={'space-between'}
                            sx={{ px: 2, pt: 1 }}
                        >
                            <Stack
                                direction={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'space-between'}
                            >
                                <Stack
                                    direction={'row'}
                                    alignItems={'flex-start'}
                                    justifyContent={'flex-start'}
                                >
                                    <Stack
                                        direction={'column'}
                                        alignItems={'flex-start'}
                                        justifyContent={'flex-start'}
                                    >
                                        <Typography variant='h6' fontWeight={'bold'}>
                                            {props.asset.name}
                                            {/* {!done && <CircularProgress size='1.5rem' sx={{ ml: 2, mt: 1 }} />} */}
                                        </Typography>
                                        <Typography variant='caption'>
                                            Last seen: {
                                                props.asset.device_update_timestamp ?
                                                    dayjs(props.asset.device_update_timestamp).isAfter(dayjs()) ? 'just now' :
                                                        <TimeAgo datetime={props.asset.device_update_timestamp} locale='en_short' /> : 'unknown'
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack
                                direction={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'flex-start'}
                                spacing={1}
                                sx={{ pt: 1 }}
                            >
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'flex-start'}
                                    spacing={1}
                                >
                                    <Tooltip title={`This asset has ${asset_routes.length} routes in the selected datetime range`}>
                                        <Chip
                                            label={asset_routes.length}
                                            size='small'
                                            variant='filled'
                                            color='primary'
                                            sx={{ fontWeight: 'bold' }}
                                            icon={<RouteIcon />}
                                        />
                                    </Tooltip>
                                    <Tooltip title={`This asset has ${asset_routes.length} works in the selected datetime range`}>
                                        <Chip
                                            label={asset_works.length}
                                            size='small'
                                            variant='outlined'
                                            sx={{ bgcolor: 'lightgray', fontWeight: 'bold' }}
                                            icon={<ConstructionIcon />}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Stack>

                            {
                                <Stack
                                    direction={'column'}
                                    alignItems={'flex-end'}
                                    justifyContent={'flex-start'}
                                >
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'flex-start'}
                                    >
                                        <Stack
                                            direction={'row'}
                                            alignItems={'flex-end'}
                                            justifyContent={'flex-end'}
                                        >
                                            {battery ? batteryIconChooser(battery) : <BatteryUnknownIcon />}
                                            <Typography variant='caption' sx={{ fontWeight: 'bold', pl: '2px', pt: '1px', pr: 1 }}>
                                                {battery ? battery + '%' : ''}
                                            </Typography>
                                        </Stack>
                                        <Chip
                                            size='small'
                                            label={
                                                props.asset.coordinate_type == 'Navigating' ? 'Navigating' :
                                                    props.asset.coordinate_type == 'Start' ? 'Navigating' :
                                                        props.asset.coordinate_type == 'Stopped' ? 'Stopped' :
                                                            props.asset.coordinate_type == 'End' ? 'Stopped' :
                                                                'default'
                                            }
                                            color={
                                                props.asset.coordinate_type == 'Navigating' ? 'success' :
                                                    props.asset.coordinate_type == 'Start' ? 'success' :
                                                        props.asset.coordinate_type == 'Stopped' ? 'error' :
                                                            props.asset.coordinate_type == 'End' ? 'error' :
                                                                'default'
                                            }
                                        />
                                    </Stack>
                                    <Typography variant='caption'>
                                        {`asset ${props.asset.id} tag ${props.asset.tag_id} ${props.asset.hardware_id}`}
                                    </Typography>
                                </Stack>
                            }
                        </Stack>
                    </Paper>
                }

                <Paper
                    sx={{ height: '100%', width: '100%', overflow: 'auto' }}
                >
                    <Stack direction='column' spacing={1} sx={{ px: 1 }}>
                        {/* <Typography variant='caption' fontWeight={'bold'}>
                            {
                                `Employee activity for [${dayjs(filters_state.work_filter.start.datetime_string).format('HH:mm DD/MM/YYYY')}] to [${dayjs(filters_state.work_filter.end.datetime_string).format('HH:mm DD/MM/YYYY')}]`
                            }
                        </Typography> */}
                        {/* <Typography variant='caption'>
                            Official work hours: {official_work_hours}
                        </Typography>
                        <Typography variant='caption'>
                            Employee work hours: {employee_work_hours}
                        </Typography>
                        <Typography variant='caption'>
                            Employee travel hours: {employee_travel_hours}
                        </Typography>
                        <Typography variant='caption'>
                            Employee activity hours: {employee_work_hours + employee_travel_hours}
                        </Typography> */}
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="400px"
                            data={bar_chart_data_hours}
                            options={
                                {
                                    title: `${props.asset ? props.asset.name : 'Combined'} activity for ${dayjs(filters_state.work_filter.start.datetime_string).format('HH:mm DD/MM/YYYY')} to ${dayjs(filters_state.work_filter.end.datetime_string).format('HH:mm DD/MM/YYYY')}:`,
                                    hAxis: {
                                        title: "Hours",
                                    },
                                    // vAxis: {
                                    //     title: "Employee",
                                    // },
                                    colors: [
                                        '#676767',//'#663399', // Official
                                        '#173c69', // Worked
                                        '#176917', // Travel
                                        '#174754', // Summed
                                        '#FFFFFF', // Combined
                                        '#2e76d0', // Allocated work
                                        '#2ed12e', // Allocated travel
                                        '#FFFFFF', // Overview
                                        '#7B98B2', // Unallocated
                                    ],
                                    bar: { groupWidth: "75%" },
                                    legend: { position: "none" }, //bottom or none
                                    isStacked: true,
                                }
                            }
                            legendToggle
                        />
                    </Stack>
                </Paper>
            </Stack>
        </Paper>
    );
};
