import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { AssetsState } from '../../../slices/assets';
import { Box, Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Asset } from '../../../models/interfaces';
import { FilterState } from '../../../slices/filter';
import ClearIcon from '@mui/icons-material/Clear';
import { TimesheetEmployeeTableRow } from './timesheet-employee-table-row';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    { field: 'type', headerName: 'Type', width: 100 },
    { field: 'name', headerName: 'Asset', width: 200 },
];

export const TimesheetEmployeeTable = (props: { setTimesheetEmployeeInfo: any }) => {
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);

    const [filtered_assets, setFilteredAssets] = useState<Asset[]>([]);
    const [sorted_assets, setSortedAssets] = useState<Asset[]>([]);

    useEffect(() => {
        setFilteredAssets(
            assets_state.assets.filter((asset: Asset) => {
                return asset.asset_type.toLowerCase() === 'employee'
            }).sort((a, b) => {
                return a.name.localeCompare(b.name);
            })
        );
    }, [filter_state.asset_type_filters, filter_state.asset_search_filter, assets_state])

    useEffect(() => {
        const grouped = filtered_assets.reduce((groups: any, asset) => {
            if (!groups[asset.asset_type]) groups[asset.asset_type] = [];
            groups[asset.asset_type].push(asset);
            return groups;
        }, {});
        let sorted: any[] = [];
        ((Object.keys(grouped) as (keyof typeof grouped)[]).forEach((key: any) => {
            (grouped[key]).forEach((asset: Asset) => {
                sorted.push(asset);
            });
        }));
        setSortedAssets(sorted);
    }, [filtered_assets])

    if (assets_state.status === 'idle') {
        return (
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'space-around'}
                height={'100%'}
            >
                <Typography>
                    Loading assets...
                </Typography>
                <CircularProgress sx={{ size: '1rem' }} />
            </Stack>
        );
    }


    return (
        <Table
            stickyHeader={true}
            size={'small'}
        >
            <TableBody>
                {
                    sorted_assets.length === 0 ?
                        (
                            <TableRow key='spacer'>
                                <TableCell colSpan={5} align='center'>
                                    Empty
                                </TableCell>
                            </TableRow>
                        )
                        :
                        (
                            [
                                ...(sorted_assets || [])?.map((asset) => {
                                    return (
                                        <TimesheetEmployeeTableRow asset={asset} key={`row-${asset.id}`} setTimesheetEmployeeInfo={props.setTimesheetEmployeeInfo} />
                                    )
                                }),
                            ]
                        )
                }
            </TableBody>
        </Table>
    );
};