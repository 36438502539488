import { Project, SubProject } from '../../../models/interfaces';
import { Box, Paper, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { ProjectSummaryDetailedTableRow } from './project-summary-detailed-table-row';
import { useEffect, useState } from 'react';
import { calculateTotalCost, calculateTotalDistance, calculateTotalDistanceCost, calculateTotalDuration, calculateTotalDurationCost, calculateTotalDurationHours, calculateTotalRouteDurationHours, calculateTotalWorkDurationHours } from '../project-utils';

const tableStyles = {
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        borderTop: '2px solid #e0e0e0',
    } as React.CSSProperties,
};

export const ProjectSummaryDetailedTable = (props: { project: Project, sub_projects: SubProject[], summary_objects: any, sub_project_colors: Record<string, string>, }) => {
    const [checked, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <Paper
            sx={{ height: '100%', width: '100%', overflow: 'auto', m: 0.5 }}>
            <Table
                stickyHeader={true}
                size={'small'}
                sx={{ height: '100%', width: '100%' }}
                onClick={(e) => e.stopPropagation()}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Type
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Sub project
                            </Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant='caption' fontWeight={'bold'}>
                                Asset
                            </Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant='caption' fontWeight={'bold'}>
                                Time
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Distance (km)
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Travel rate
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Distance cost
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Travel hours
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Work hours
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Hourly rate
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'} align='right'>
                                Time cost
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='caption' fontWeight={'bold'} align='right'>
                                Total cost
                            </Typography>
                        </TableCell>
                        <TableCell width={'200px'}>
                            <Typography variant='caption' fontWeight={'bold'}>
                                Description
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ p: 0, m: 0, px: 0.5 }}>
                            <Tooltip title={'Show unassign items'}>
                                <Switch checked={checked} onChange={handleChange} size='small' />
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.summary_objects.length === 0 ?
                            (
                                <TableRow key='spacer'>
                                    <TableCell colSpan={14} align='center'>
                                        Empty
                                    </TableCell>
                                </TableRow>
                            )
                            :
                            (
                                [
                                    ...props.summary_objects.map((object: any) =>
                                        <ProjectSummaryDetailedTableRow key={object.id} summary_object={object} remove={checked} sub_project_colors={props.sub_project_colors} />
                                    ),
                                    <TableRow key='spacer'>
                                        <TableCell colSpan={14} align='center' height={'100%'}>
                                            {/* empty */}
                                        </TableCell>
                                    </TableRow>
                                ]
                            )
                    }
                </TableBody>
                <TableFooter style={tableStyles.stickyFooter}>
                    <TableRow sx={{ backgroundColor: 'background.paper' }}>
                        <TableCell colSpan={2}>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`Total items: ${props.summary_objects.length}`}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {/* empty cell */}
                        </TableCell>
                        <TableCell>
                            {/* empty cell */}
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`${(calculateTotalDistance(props.summary_objects) / 1000.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {/* empty cell */}
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`R ${(calculateTotalDistanceCost(props.summary_objects) / 100.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {calculateTotalRouteDurationHours(props.summary_objects).toFixed(2)}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {calculateTotalWorkDurationHours(props.summary_objects).toFixed(2)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {/* empty cell */}
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`R ${(calculateTotalDurationCost(props.summary_objects) / 100.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography variant='body2' fontWeight={'bold'}>
                                {`R ${(calculateTotalCost(props.summary_objects) / 100.0).toFixed(2)}`}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {/* empty cell */}
                        </TableCell>
                        <TableCell>
                            {/* empty cell */}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
}