import { Checkbox, Chip, CircularProgress, IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import RouteIcon from '@mui/icons-material/Route';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useDispatch } from 'react-redux';
import Close from '@mui/icons-material/Close';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Project } from '../../models/interfaces';
import { AppDispatch } from '../../store';

export const TimesheetProjectsTableRow = (props: {
    project: Project,
    handleProjectSelected: any,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [is_busy, setIsBusy] = useState(false);

    return (
        <TableRow
            hover
            onClick={(e) => {
                e.stopPropagation();
                props.handleProjectSelected(props.project)
            }}
        >
            <TableCell
                sx={{ px: 0.5 }}
            >
                <Tooltip title={`This is project ${props.project.project_code}`}>
                    <Chip
                        label={`${props.project.project_code}`}
                        size='small'
                        variant='outlined'
                    // icon={<AccountTreeIcon />}
                    />
                </Tooltip>
            </TableCell>
            <TableCell>
                {
                    props.project.description
                }
            </TableCell>
        </TableRow>
    );
}

