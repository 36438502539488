import { useState, useEffect } from 'react';
import { Modal, Popover, Menu, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableFooter, TablePagination, TableRow, Typography, Chip, CircularProgress, TextField, Backdrop, Tooltip, MenuItem, ButtonGroup } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { ClearIcon, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDispatch, useSelector } from 'react-redux';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch } from '../../store';
import { selectAllRates } from '../../slices/rates';
import { AssetsState } from '../../slices/assets';
import { Asset, AssetWork, Project, SubProject, Work } from '../../models/interfaces';
import { determineDay } from '../project/project-utils';
import { addServerWork, retrieveWorksById } from '../../slices/works';
import { assignProject, retrieveProjectsById } from '../../slices/projects';
import { SubProjectTable } from '../project/sub-projects-table/sub-projects-table';
import { useTheme } from '@mui/material';
import { TimesheetProjectsTable } from './timesheet-projects-table';
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const TimesheetAddWork = (props: {
    work_start_time: Dayjs | undefined,
    work_end_time: Dayjs | undefined,
    work_assset: Asset | undefined,
    open_add_work: any,
    setOpenAddWork: any
}) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const rates = useSelector(selectAllRates);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const [sub_project_colors, setSubProjectColors] = useState<Record<string, string>>({});

    const [open_modal, setOpenModal] = useState(false);
    const [mouse_caught, setMouseCaught] = useState(false);
    const [is_busy, setIsBusy] = useState(false);
    const [is_error, setIsError] = useState(false);
    const [selected_project, setSelectedProject] = useState<Project>();
    const [selected_sub_projects, setSelectedSubProjects] = useState<SubProject[]>(selected_project?.sub_projects.find(subproject => subproject.name.toLowerCase() === 'default') ? [selected_project?.sub_projects.find(subproject => subproject.name.toLowerCase() === 'default')!] : []);
    const [anchor_project_menu, setAnchorProjectMenu] = useState<null | HTMLElement>(null);
    const [anchor_sub_project_menu, setAnchorSubProjectMenu] = useState<null | HTMLElement>(null);
    const [open_project_menu, setOpenProjectMenu] = useState(false);
    const [open_sub_project_menu, setOpenSubProjectMenu] = useState(false);

    const [work_description, setWorkDescription] = useState<string>('');
    const [work_rate, setWorkRate] = useState<number | string>(0);
    const [work_start, setWorkStart] = useState<Dayjs | null>(props.work_start_time ? props.work_start_time : dayjs().set('hour', 7).set('minute', 0).set('second', 0));
    const [work_end, setWorkEnd] = useState<Dayjs | null>(props.work_end_time ? props.work_end_time : dayjs().set('hour', 16).set('minute', 0).set('second', 0));
    const [work_duration, setWorkDuration] = useState('unknown');
    const [work_rate_valid, setWorkRateValid] = useState(true);
    const [work_cost, setWorkCost] = useState<number>(0);
    const [work_assets, setWorkAssets] = useState<Asset[]>([]);
    const work_day_types = [{ day: 'Sunday', factor: 2 }, { day: 'Saturday', factor: 1.5 }, { day: 'Weekday', factor: 1 }, { day: 'Public Holiday', factor: 2 },]
    const [work_day_type, setWorkDayType] = useState('');
    const [work_rate_factor, setWorkRateFactor] = useState(1);

    const [anchor_employee_menu, setAnchorEmployeeMenu] = useState<HTMLElement | null>(null);
    const [open_employee_menu, setOpenEmployeeMenu] = useState(false);
    const [anchor_day_type_menu, setAnchorDayTypeMenu] = useState<HTMLElement | null>(null);
    const [open_day_type_menu, setOpenDayTypeMenu] = useState(false);

    useEffect(() => {
        if (props.open_add_work) {
            props.setOpenAddWork(false);
            setOpenModal(true);
        }
    }, [props.open_add_work]);

    useEffect(() => {
        setSelectedProject(undefined);
        setSelectedSubProjects([]);
    }, [props.work_start_time, props.work_end_time]);

    useEffect(() => {
        if (props.work_start_time && props.work_end_time && props.work_assset) {
            setWorkStart(props.work_start_time);
            setWorkEnd(props.work_end_time);
            setWorkAssets([props.work_assset]);
        }

    }, [props.work_start_time, props.work_end_time, props.work_assset])


    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        if (!mouse_caught) {
            setOpenModal(false);

            setWorkDescription('');
            setWorkAssets([]);
        }
        setMouseCaught(false);
        props.setOpenAddWork(false);
    }

    useEffect(() => {
        // Calculate and set sub-project colors when the component mounts or when sub-projects change
        const calculated_colors: Record<string, string> = {};
        if (selected_project) {
            selected_project.sub_projects.forEach((subProject, index) => {
                let color;
                // 'default' sub-project gets a lighter shade of MUI primary blue
                if (subProject.name.toLowerCase() === 'default') {
                    const lightness = theme.palette.mode === 'dark' ? 30 : 90;
                    color = `hsl(210, 75%, ${lightness}%)`;
                } else {
                    const hue = (index * 137) % 360;
                    const lightness = theme.palette.mode === 'dark' ? 30 : 85;
                    color = `hsl(${hue}, 75%, ${lightness}%)`;
                }
                calculated_colors[subProject.id] = color;
            });
        }

        setSubProjectColors(calculated_colors);
    }, [selected_project, theme.palette.mode]);

    //Calculate work duration
    useEffect(() => {
        const diff = work_end?.diff(work_start);
        if (diff && diff > 0) {
            const work_duration = dayjs.duration(diff, 'milliseconds');
            const years = work_duration.years();
            const days = work_duration.days();
            const hours = work_duration.hours() + days * 24 + years * 365;
            const minutes = work_duration.minutes();
            const seconds = work_duration.seconds();
            setWorkDuration(`${hours} hours, ${minutes} minutes, ${seconds} seconds`);
            setWorkDayType(determineDay(dayjs(work_start)))
        }
        else {
            setWorkDuration('unknown');
        }
    }, [work_start, work_end])

    useEffect(() => {
        setWorkRateFactor(work_day_types.find(day_type => day_type.day === work_day_type)?.factor || 1);
    }, [work_day_type])

    const handleWorkRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkRate(event.target.value);
        const input_value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(input_value) || input_value === '') {
            setWorkRateValid(true)
        } else {
            setWorkRateValid(false);
        }
    };

    useEffect(() => {
        const diff = work_end?.diff(work_start);
        if (diff && diff > 0 && work_rate_valid) {
            const hours = diff / 1000 / 60 / 60;
            const cost = work_assets.reduce((cost, asset) => {
                return cost + (asset.rate?.hourly_rate || 0) * work_rate_factor * hours;
            }, 0);
            setWorkCost(cost);
            // setWorkCost(diff / 1000.0 / 60.0 / 60.0 * Number(work_rate));
        } else {
            setWorkCost(-1)
        }
    }, [work_duration, work_rate, work_day_type, work_assets])

    const handleAddWork = () => {
        setOpenModal(false);
        setIsBusy(true);
        const new_work: Work = {
            id: 0,
            asset_id: null,
            asset: null,
            tag_id: null,
            latitude: null,
            longitude: null,
            server_start_time: dayjs().toISOString(),
            server_end_time: dayjs().toISOString(),
            device_start_time: work_start?.toISOString() || '',
            device_end_time: work_end?.toISOString() || '',
            device_duration: 0,
            work_time_adjustment: 0,
            is_ignored: false,
            description: work_description,
            note: '',
            start_route: null,
            end_route: null,
            asset_works: work_assets.map(work_asset => {
                let new_asset_work: AssetWork = {
                    id: 0,
                    server_timestamp_created: null,
                    server_timestamp_updated: null,
                    asset: undefined,
                    rate_id: undefined,
                    rate: work_asset.rate,
                    asset_id: work_asset.id,
                    work_id: undefined,
                    needs_approval: false,
                    auto_linked: false,
                }
                return new_asset_work;
            }),
            list_of_sub_projects: selected_project ? [selected_project.sub_projects.find(sub_project => sub_project.name.toLowerCase() === 'default')!.id] : [],
            rate_factor: work_rate_factor,
            rate_day_type: work_day_type,
            list_of_sub_projects_count: undefined,
            costing_factor: undefined,
        }
        dispatch(addServerWork({
            work: new_work,
        })).then((result) => {
            if (result.type === 'works/add_server_work/fulfilled') {
                console.log('new work created');
                const payload: any = result.payload;
                const created_work_id: number = payload[0].id;
                if (created_work_id && selected_project && selected_sub_projects.length > 0) {
                    selected_sub_projects.forEach((selected_sub_project) => {
                        assignWorkToSubProject(created_work_id, selected_sub_project, selected_project);
                    });
                }
            }
            else {
                console.log('new work create error');
                setIsError(true);
            }
            setIsBusy(false);
        });
    }

    const assignWorkToSubProject = (created_work_id: number, sub_project: SubProject, project: Project) => {
        if (selected_sub_projects.length > 0) {
            console.log('assigning work to project')
            dispatch(assignProject({
                sub_project_ids: [sub_project.id],
                route_ids: [],
                work_ids: [created_work_id], //new work id
                do_remove: false,
            })).then((result) => {
                if (result.type === 'projects/assign_project/fulfilled') {
                    console.log('work assigned');
                    dispatch(retrieveProjectsById({ ids: [project.id] }));
                    dispatch(retrieveWorksById({ ids: [created_work_id] })); //new work id
                    setIsError(false);
                } else {
                    console.log('work assign failed, retrying');
                    setTimeout(() => { assignWorkToSubProject(created_work_id, sub_project, project) }, 3000)
                }
            });
        }
    }

    const handleClickCheckbox = (sub_project: SubProject) => {
        const is_selected = isSubProjectSelected(sub_project);
        if (is_selected === false) {
            setSelectedSubProjects((selected) => [...selected, sub_project]);
        }
        else {
            setSelectedSubProjects((selected) => selected.filter((s) => s.id !== sub_project.id));
        }
    }

    const isSubProjectSelected = (sub_project: SubProject) => {
        const is_selected = selected_sub_projects.map((selected_sub_project) => selected_sub_project.id).includes(sub_project.id);
        return is_selected;
    }

    const handleSelectAllClick = (e: any) => {
        if (selected_project) {
            if (e.target.checked === true) {
                setSelectedSubProjects(selected_project.sub_projects);
                return;
            }
            setSelectedSubProjects([]);
        }
    };

    const handleProjectSelected = (project: Project | undefined) => {
        setSelectedProject(project);
        if (project) {
            setSelectedSubProjects(project.sub_projects.filter(subproject => subproject.name.toLowerCase() === 'default'));
        }
        setAnchorProjectMenu(null);
        setOpenProjectMenu(false);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ p: 0, m: 0 }}
            >
                {
                    is_busy ?
                        <CircularProgress size={'1rem'} />
                        :
                        <Tooltip title={is_error ? 'An error occurred when trying to add' : 'Add work'}>
                            {/* <Chip
                                label={is_error ? '!' : '+'}
                                sx={{ fontWeight: 'bold', pl: 0.5 }}
                                size='small'
                                variant='filled'
                                color={is_error ? 'error' : 'primary'}
                                icon={<ConstructionIcon color={is_error ? 'error' : 'inherit'} />}
                                onClick={handleOpenModal}
                            /> */}
                            <Button
                                onClick={handleOpenModal}
                                size='small'
                                variant='contained'
                                color={is_error ? 'error' : 'primary'}
                                sx={{ height: '100%' }}
                            >
                                <ConstructionIcon
                                    color={'inherit'}
                                    sx={{ fontSize: '1.25rem' }}
                                />
                                <Typography variant='caption' sx={{ fontWeight: 'bold' }} fontSize={'medium'}>
                                    {is_error ? '!' : '+'}
                                </Typography>
                            </Button>
                        </Tooltip>
                }
            </Stack>
            <Modal
                open={open_modal}
                onClose={handleCloseModal}
                sx={{ height: '100vh', width: '100vw' }}
            >
                <div onClick={handleCloseModal}>
                    <Stack
                        sx={{ height: '100vh', width: '100vw' }}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Paper onClick={(e) => e.stopPropagation()} onMouseDown={() => setMouseCaught(true)} sx={{ overflow: 'auto' }}>
                            <Stack
                                direction={'column'}
                                onKeyDown={(e) => e.stopPropagation()}
                                sx={{ m: 2 }}
                                spacing={2}
                            >
                                {/* Add work title */}
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ m: 2 }} spacing={2}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                                        <ConstructionIcon />
                                        <Typography variant='h6' fontWeight={'bold'}>
                                            {`Add new work`}
                                        </Typography>
                                    </Stack>
                                    <>
                                        <Chip
                                            label={selected_project ? `${selected_project.project_code}` : 'select project'}
                                            size='small'
                                            variant={selected_project ? 'filled' : 'outlined'}
                                            color={selected_project ? 'success' : 'error'}
                                            sx={{ bgcolor: selected_project ? 'success' : 'error', fontWeight: 'bold' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setAnchorProjectMenu(e.currentTarget);
                                                setOpenProjectMenu(true);
                                            }}
                                        />
                                        <Menu
                                            open={open_project_menu}
                                            anchorEl={anchor_project_menu}
                                            onClose={(e) => {
                                                setOpenProjectMenu(false);
                                                setAnchorProjectMenu(null);
                                            }}
                                            transitionDuration={0}
                                        >
                                            <TimesheetProjectsTable handleProjectSelected={handleProjectSelected} />
                                        </Menu>
                                    </>
                                    {
                                        selected_project &&
                                        <>
                                            <Chip
                                                label={selected_sub_projects.length > 0 ? selected_sub_projects.length === 1 ? selected_sub_projects[0]?.name.toLowerCase() : 'multiple' : 'none'}
                                                size='small'
                                                variant={selected_sub_projects.length === 1 ? 'outlined' : 'filled'}
                                                color={selected_sub_projects.length === 0 ? 'error' : selected_sub_projects.length > 1 ? 'success' : undefined}
                                                sx={{ bgcolor: selected_sub_projects.length === 1 ? sub_project_colors[selected_sub_projects[0].id] : null, fontWeight: 'bold' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setAnchorSubProjectMenu(e.currentTarget);
                                                    setOpenSubProjectMenu(true);
                                                }}
                                            />
                                            <Menu
                                                open={open_sub_project_menu}
                                                anchorEl={anchor_sub_project_menu}
                                                onClose={(e) => {
                                                    setOpenSubProjectMenu(false);
                                                    setAnchorSubProjectMenu(null);
                                                }}
                                                transitionDuration={0}
                                            >
                                                {
                                                    selected_project &&
                                                    <SubProjectTable
                                                        project={selected_project}
                                                        sub_projects={
                                                            [...selected_project.sub_projects]
                                                                .sort((a, b) => a.name.toLowerCase() === 'default' ? -1 : b.name.toLowerCase() === 'default' ? 1 : a.name.localeCompare(b.name))}
                                                        selected_sub_projects={selected_sub_projects}
                                                        handleClickCheckbox={handleClickCheckbox}
                                                        isSubProjectSelected={isSubProjectSelected}
                                                        handleSelectAllClick={handleSelectAllClick}
                                                        sub_project_colors={sub_project_colors}
                                                    />
                                                }
                                            </Menu>
                                        </>
                                    }


                                </Stack>
                                {/* Work assets (Employees) */}
                                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={1}>
                                    <Typography variant='caption' fontWeight={'bold'} color={work_assets.length === 0 ? 'error' : 'inherit'}>
                                        Employees
                                    </Typography>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ m: 1, p: 1 }}>
                                                    <Typography variant='caption' fontWeight={'bold'} >
                                                        Name
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ m: 1, p: 1 }}>
                                                    <Typography variant='caption' fontWeight={'bold'}>
                                                        Description
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ m: 1, p: 1 }}>
                                                    <Typography variant='caption' fontWeight={'bold'}>
                                                        Base [R/h]
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ m: 1, p: 1 }}>
                                                    <Typography variant='caption' fontWeight={'bold'}>
                                                        Factor
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align='right' sx={{ m: 1, p: 1 }}>
                                                    <Typography variant='caption' fontWeight={'bold'}>
                                                        [R/h]
                                                    </Typography>
                                                </TableCell>
                                                <TableCell width={'10px'}>
                                                    {/* empty */}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                work_assets.map(work_asset => {
                                                    return (
                                                        <TableRow key={`${work_asset.id}`} hover>
                                                            <TableCell sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption'>
                                                                    {work_asset?.name || 'None'}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption'>
                                                                    {work_asset?.rate?.name || 'None'}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align='right' sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption'>
                                                                    {work_asset?.rate?.hourly_rate || 'None'}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align='right' sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption'>
                                                                    {work_rate_factor}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align='right' sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption'>
                                                                    {work_asset?.rate?.hourly_rate * work_rate_factor || 'None'}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton size='small' color='error' onClick={() => {
                                                                    setWorkAssets((a) => a.filter(asset => asset.id !== work_asset.id))
                                                                }}>
                                                                    <CloseIcon fontSize='inherit' />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (anchor_employee_menu === null && open_employee_menu === false) {
                                                    setAnchorEmployeeMenu(e.currentTarget); setOpenEmployeeMenu(true);
                                                }
                                            }}
                                        >
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                                Add
                                                <KeyboardArrowDown />
                                            </Stack>
                                        </Button>
                                        <Menu
                                            anchorEl={anchor_employee_menu}
                                            open={open_employee_menu}
                                            onClose={(e) => {
                                                setAnchorEmployeeMenu(null);
                                                setOpenEmployeeMenu(false);
                                            }}
                                            MenuListProps={{ sx: { p: 0 } }}
                                            transitionDuration={0}
                                        >
                                            <Stack>
                                                <Table size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption' fontWeight={'bold'} >
                                                                    Name
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption' fontWeight={'bold'}>
                                                                    Description
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align='right' sx={{ m: 1, p: 1 }}>
                                                                <Typography variant='caption' fontWeight={'bold'}>
                                                                    Base [R/h]
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {[...assets_state.assets].filter(asset => asset.asset_type === 'Employee')
                                                            .sort((a, b) => a.name?.localeCompare(b.name))
                                                            .filter(asset => !work_assets.map(work_asset => work_asset.id).includes(asset.id))
                                                            .map(asset => {
                                                                return (
                                                                    // <MenuItem
                                                                    //     onClick={(e) => {
                                                                    //         e.stopPropagation();
                                                                    //         setAnchorEmployeeMenu(null);
                                                                    //         setOpenEmployeeMenu(false);
                                                                    //         setWorkAssets((a) => Array.from(new Set([...a, asset])));
                                                                    //     }}>
                                                                    <TableRow
                                                                        key={`${asset.id}`}
                                                                        hover
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setAnchorEmployeeMenu(null);
                                                                            setOpenEmployeeMenu(false);
                                                                            setWorkAssets((a) => Array.from(new Set([...a, asset])));
                                                                        }}
                                                                    >
                                                                        <TableCell sx={{ m: 1, p: 1 }}>
                                                                            <Typography variant='caption'>
                                                                                {asset.name}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell sx={{ m: 1, p: 1 }}>
                                                                            <Typography variant='caption'>
                                                                                {asset.rate?.name}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align='right' sx={{ m: 1, p: 1 }}>
                                                                            <Typography variant='caption'>
                                                                                {asset.rate?.hourly_rate}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    // </MenuItem>
                                                                )
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </Stack>
                                        </Menu>
                                        {
                                            work_assets.length > 0 &&
                                            <Button
                                                variant='outlined'
                                                size='small'
                                                color='error'
                                                onClick={(e) => {
                                                    setWorkAssets([]);
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        }
                                    </Stack>
                                </Stack>
                                {/* Work description */}
                                <FormControl
                                    id={`form-projects-work-description`}
                                    variant='outlined'
                                    sx={{ minWidth: '400px', mt: '2px' }}
                                >
                                    {/* <InputLabel htmlFor={`work-description`}>
                                        Work description
                                        </InputLabel> */}
                                    <TextField
                                        id={`work-description`}
                                        multiline
                                        maxRows={4}
                                        minRows={1}
                                        label='Work description'
                                        value={work_description}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setWorkDescription(event.target.value);
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end' sx={{ m: 0, p: 0 }}>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setWorkDescription('');
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ size: '1rem' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                {/* Work start */}
                                <DateTimePicker
                                    maxDateTime={work_end}
                                    label='Start time'
                                    value={work_start}
                                    onChange={(value, context) => {
                                        if (context.validationError !== 'maxDate') {
                                            setWorkStart(value);
                                        }
                                        else {
                                            setWorkStart(value);
                                        }

                                    }}
                                    ampm={false}
                                    sx={{ my: 1 }}
                                    format='YYYY/MM/DD - HH:mm'
                                />
                                {/* Work end */}
                                <DateTimePicker
                                    minDateTime={work_start}
                                    label='End time'
                                    value={work_end}
                                    onChange={(value, context) => {
                                        if (context.validationError !== 'minDate') {
                                            setWorkEnd(value);
                                        }
                                        else {
                                            setWorkEnd(value);
                                        }
                                    }}
                                    ampm={false}
                                    sx={{ my: 1 }}
                                    format='YYYY/MM/DD - HH:mm'
                                />
                                {/* Work rate */}
                                {/* <FormControl
                                    id={`form-projects-work-rate`}
                                    variant='outlined'
                                    sx={{ minWidth: '120px', mt: '2px' }}
                                    color={work_rate_valid ? 'primary' : 'error'}
                                    >
                                    <InputLabel htmlFor={`work-rate`}>Work rate (R/h)</InputLabel>
                                    <OutlinedInput
                                    id={`work-rate`}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                        <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setWorkRate(0);
                                            }}
                                            >
                                            <ClearIcon sx={{ size: '1rem' }} />
                                            </IconButton>
                                            </InputAdornment>
                                            }
                                            label='Work rate (R/h)'
                                            value={work_rate}
                                            onChange={handleWorkRateChange}
                                            onClick={(e) => e.stopPropagation()}
                                            />
                                            </FormControl> */}
                                <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} spacing={1}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (anchor_day_type_menu === null && open_day_type_menu === false) {
                                                    setAnchorDayTypeMenu(e.currentTarget); setOpenDayTypeMenu(true);
                                                }
                                            }}
                                        >
                                            {work_day_type ? work_day_type : 'Day type'}
                                        </Button>
                                        <Typography fontWeight={'bold'} variant='caption'>
                                            {`Factor: ${work_rate_factor.toFixed(1)}`}
                                        </Typography>
                                    </Stack>
                                    <Menu
                                        anchorEl={anchor_day_type_menu}
                                        open={open_day_type_menu}
                                        onClose={(e) => {
                                            setAnchorDayTypeMenu(null);
                                            setOpenDayTypeMenu(false);
                                        }}
                                        MenuListProps={{ sx: { p: 0 } }}
                                        transitionDuration={0}
                                    >
                                        <MenuItem>
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} spacing={4}>
                                                <Typography variant='caption' fontWeight={'bold'}>
                                                    Description
                                                </Typography>
                                                <Typography variant='caption' fontWeight={'bold'}>
                                                    Rate [R/h]
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                        {[...work_day_types].sort((a, b) => a.factor - b.factor).map(rate => {
                                            return (
                                                <MenuItem key={`${rate.day}`} onClick={(e) => {
                                                    e.stopPropagation();
                                                    setAnchorDayTypeMenu(null);
                                                    setOpenDayTypeMenu(false);
                                                    setWorkDayType(rate.day);
                                                }}>
                                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} spacing={2}>
                                                        <Typography variant='caption'>
                                                            {rate.day}
                                                        </Typography>
                                                        <Typography variant='caption'>
                                                            {rate.factor}
                                                        </Typography>
                                                    </Stack>
                                                </MenuItem>
                                            )
                                        })}
                                    </Menu>
                                </Stack>
                                {/* Work duration (calculated) */}
                                <Typography>
                                    {`Duration: ${work_duration}`}
                                </Typography>
                                {/* Work cost (calculated) */}
                                <Typography fontWeight={'bold'}>
                                    {`Cost: R ${work_cost >= 0 ? work_cost.toFixed(2) : 'invalid input'}`}
                                </Typography>
                                {/* Add new work button */}
                                <Button
                                    onClick={handleAddWork}
                                    variant='contained'
                                    fullWidth
                                    disabled={!work_rate_valid || work_assets.length === 0}
                                    sx={{ mx: 1, mt: 2 }}
                                >
                                    Add new work
                                </Button>
                            </Stack>
                        </Paper>
                    </Stack>
                </div>
            </Modal >
        </LocalizationProvider >
    );
}