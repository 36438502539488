import 'leaflet/dist/leaflet.css';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer, ZoomControl, Tooltip as TooltipLeaflet, CircleMarker, useMapEvents, useMapEvent, Pane } from 'react-leaflet';
import { CircularProgress, Paper, Stack, TableBody, Tooltip as TooltipMui, Typography } from '@mui/material';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import map from '../../slices/map';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { setCenter, setZoom } from '../../slices/map';
import { LayersControl, LayerGroup, FeatureGroup, Circle, Rectangle } from 'react-leaflet';
import { MapState } from '../../slices/map';
import { Asset, Route, Tag, Work } from '../../models/interfaces';
import { AssetsState } from '../../slices/assets';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { LatLng, LeafletMouseEvent, Map, icon } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import { Button, IconButton, Menu, PopoverPosition } from '@mui/material';
import L from 'leaflet';
import './map.css';
import { Polyline } from 'react-leaflet';
import { RoutesState } from '../../slices/routes';
import { RouteFilter, FilterState, setSelectFilterSelectedAsset, addSelectFilterSelectedRoutes, addSelectFilterSelectedWorks, removeSelectFilterSelectedWorks, removeSelectFilterSelectedRoutes, setSelectFilterSelectedRoutes, setSelectFilterSelectedWorks, setDetailedSelectedRoute } from '../../slices/filter';
import dayjs, { Dayjs } from 'dayjs';
import { WorksState } from '../../slices/works';
import { AssetInfoInstallation } from '../asset/asset-info-installation';
import { AssetInfoVehicle } from '../asset/asset-info-vehicle';
import { TagsState } from '../../slices/tags';
import { ProjectAssignMenu } from '../project/project-assign-menu/project-assign-menu';
import { initialState } from '../../slices/map';
import Control from 'react-leaflet-custom-control';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import { COMMIT_SHA, BUILD_TIMESTAMP } from '../.././build_variables.js';
import tagUploadService from '../../services/tag-upload.service';
import * as geolib from 'geolib';
import { AssetInfoEmployee } from '../asset/asset-info-employee';

const version = `${dayjs(BUILD_TIMESTAMP).isValid() ? `Version: ${dayjs(BUILD_TIMESTAMP).format('HH:mm:ss DD/MM/YYYY')}` : 'Development build'}`

const VEHICLE_GREEN = icon({
    iconUrl: '/vehicle-green.png',
    iconSize: [48, 48],
})

const VEHICLE_BLANK = icon({
    iconUrl: '/vehicle-blank.png',
    iconSize: [48, 48],
})

const EMPLOYEE = icon({
    iconUrl: '/employee.png',
    iconSize: [48, 48],
})

const INSTALLATION = icon({
    iconUrl: '/installation.png',
    iconSize: [48, 48],
})

const { BaseLayer, Overlay } = LayersControl;
const map_max_zoom = 24;

const MapAssets = (props: { refmap: any, map: any, setAssetInfo: any }) => {
    const { center, zoom } = useSelector((state: { map: MapState }) => state.map);
    const map_state = useSelector((state: { map: MapState }) => state.map);
    const assets_state = useSelector((state: { assets: AssetsState }) => state.assets);
    const routes_state = useSelector((state: { routes: RoutesState }) => state.routes);
    const works_state = useSelector((state: { works: WorksState }) => state.works);
    const tags_state = useSelector((state: { tags: TagsState }) => state.tags);
    const filtered_routes = useSelector((state: { filter: FilterState }) => state.filter.route_filter.routes);
    const filtered_works = useSelector((state: { filter: FilterState }) => state.filter.work_filter.works);
    const filter_state = useSelector((state: { filter: FilterState }) => state.filter);
    const dispatch = useDispatch<AppDispatch>();


    //Project assign context menu
    const [selected_asset, setSelectedAsset] = useState<Asset>();
    const [selected_route, setSelectedRoutes] = useState<Route[]>();
    const [selected_work, setSelectedWorks] = useState<Work[]>();

    const [open_project_assign_menu, setOpenProjectAssignMenu] = useState(false);
    const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>(undefined);

    const handleCloseAssignProjectMenu = () => {
        setOpenProjectAssignMenu(false);
        setAnchorPosition(undefined);
    };

    const handleClickAssignProject = (e: LeafletMouseEvent) => {
        e.originalEvent.preventDefault();
        const { clientX, clientY } = e.originalEvent;
        setAnchorPosition({ top: clientY, left: clientX });
        setOpenProjectAssignMenu(true);
    };

    const HandleMapClick = () => {
        const map = useMapEvent('click', (e) => {
            const { lat, lng } = e.latlng;
            // console.log(`Map clicked at latitude ${lat} and longitude ${lng}`);
            dispatch(setSelectFilterSelectedRoutes([]));
            dispatch(setSelectFilterSelectedWorks([]));
            dispatch(setDetailedSelectedRoute(undefined));
            if (filter_state.select_filter.selected_routes.length == 0 && filter_state.select_filter.selected_works.length == 0) {
                dispatch(setSelectFilterSelectedAsset(undefined));
                props.setAssetInfo(undefined);
            }
        })
        return null;
    }

    const HandleMapRightClick = () => {
        const map = useMapEvent('contextmenu', (e) => {
            const { lat, lng } = e.latlng;
            // console.log(`Map clicked at latitude ${lat} and longitude ${lng}`);
            if (filter_state.select_filter.selected_routes.length > 0 || filter_state.select_filter.selected_works.length > 0) {
                const asset = filter_state.select_filter.selected_asset;
                if (asset) {
                    setSelectedAsset(asset);
                    setSelectedRoutes(filter_state.select_filter.selected_routes);
                    setSelectedWorks(filter_state.select_filter.selected_works);
                    handleClickAssignProject(e);
                }
            }
            // else {
            //     dispatch(setSelectFilterSelectedAsset(undefined));
            //     dispatch(setSelectFilterSelectedRoutes([]));
            //     dispatch(setSelectFilterSelectedWorks([]));
            // }
        })
        return null;
    }

    const HandleMapZoom = () => {
        const map = useMapEvent('zoom', (e) => {
            const z = props.map.getZoom();
            setZoom(z);
        })
        return null;
    }

    const createClusterCustomIcon = function (cluster: any) {
        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: 'custom-marker-cluster',
            iconSize: L.point(36, 36, true),
        })
    }

    useEffect(() => {
        props.map?.on('click', function (e: any) {

        });
        try {
            props.map?.attributionControl.setPrefix('');
        } catch (error) {
            console.log(error);
        }
    }, [props.map])


    const filterRoute = (r: Route) => {
        const route_start = dayjs(r.device_start_time);
        const route_end = dayjs(r.device_end_time);
        const filter_start = dayjs(filter_state.route_filter.start.datetime_string);
        const filter_end = dayjs(filter_state.route_filter.end.datetime_string);
        const selected_asset = filter_state.select_filter.selected_asset;
        if (route_start.isAfter(filter_start) && route_start.isBefore(filter_end)) {
            if (selected_asset === undefined) {
                return true;
            }
            if (selected_asset && r.asset_routes.map(ar => ar.asset_id).includes(assets_state.assets.find(a => a.tag_id === selected_asset.tag_id)?.id)) {
                return true;
            }
        }
        return false;
    };

    const filterWork = (w: Work) => {
        const work_start = dayjs(w.device_start_time);
        const work_end = dayjs(w.device_end_time);
        const filter_start = dayjs(filter_state.work_filter.start.datetime_string);
        const filter_end = dayjs(filter_state.work_filter.end.datetime_string);
        const selected_asset = filter_state.select_filter.selected_asset;
        if (work_start.isAfter(filter_start) && work_start.isBefore(filter_end)) {
            if (selected_asset === undefined) {
                return true;
            }
            if (selected_asset && w.asset_works.map(aw => aw.asset_id).includes(assets_state.assets.find(a => a.tag_id === selected_asset.tag_id)?.id)) {
                return true;
            }
        }
        return false;
    };

    const [route_elements, setRouteElements] = useState<any>([]);
    useEffect(() => {
        const r = buildRouteElements();
        setRouteElements(r);
    }, [
        filtered_routes,
        filter_state.route_filter,
        filter_state.asset_type_filters,
        filter_state.select_filter.selected_asset,
        filter_state.select_filter.selected_routes,
        filter_state.show_ignored_filter,
    ])

    const buildRouteElements = () => {
        const buildRouteMarkers = (route: Route, is_selected: boolean) => {
            const length = route.coordinates.length;
            const start = route?.coordinates[0];
            const end = route?.coordinates[length - 1];
            const is_assigned = route.list_of_sub_projects.length > 0;
            const asset = assets_state.assets.find((asset) => asset.tag_id == route.tag_id);
            return [
                <CircleMarker
                    key={`start-${route.id}`}
                    center={[start.lat, start.lng]}
                    pathOptions={{ color: 'green' }}
                    radius={5}
                >
                </CircleMarker>,
                <CircleMarker
                    key={`end-${route.id}`}
                    center={[end.lat, end.lng]}
                    pathOptions={{ color: 'red' }}
                    radius={5}
                >
                </CircleMarker>,
                <Polyline
                    key={`route-${route.id}`}
                    className='ant-path'
                    positions={route?.coordinates}
                    pathOptions={{ color: is_selected ? 'black' : is_assigned ? 'gray' : '#3388FF', weight: 4 }}
                    eventHandlers={{
                        click: (e: LeafletMouseEvent) => {
                            L.DomEvent.stopPropagation(e);
                            dispatch(setSelectFilterSelectedAsset(asset));
                            if (asset?.asset_type === 'Vehicle') {
                                props.setAssetInfo(
                                    <AssetInfoVehicle map={props.map} asset={asset} />
                                )
                            }
                            // Check if the Ctrl key is pressed (or Command key on macOS)
                            if (e.originalEvent.ctrlKey) {
                                if (!filter_state.select_filter.selected_routes.map((route) => route.id).includes(route.id)) {

                                    dispatch(addSelectFilterSelectedRoutes([route]));
                                    if (e.originalEvent.altKey) {
                                        dispatch(setDetailedSelectedRoute(route));
                                    }
                                }
                                else {
                                    dispatch(removeSelectFilterSelectedRoutes([route]));
                                }
                            }
                            else {
                                // Regular click without Ctrl key: Clear previous selections and select the current Polyline   
                                dispatch(setSelectFilterSelectedRoutes([route]));
                                dispatch(setSelectFilterSelectedWorks([]));
                            }
                        },
                        // contextmenu: (e: LeafletMouseEvent) => {
                        //     L.DomEvent.stopPropagation(e);
                        //     dispatch(setSelectFilterSelectedAsset(asset));
                        //     dispatch(addSelectFilterSelectedRoutes([route]));
                        //     setSelectedAsset(asset);
                        //     setSelectedRoutes(filter_state.select_filter.selected_routes);
                        //     setSelectedWorks(filter_state.select_filter.selected_works);
                        //     handleClickAssignProject(e);
                        // }
                    }}
                />,
            ]
        }
        //Selected marker drawn last to put it on top layer
        let markers = (filtered_routes || []).map((route: Route) => {
            const is_selected = filter_state.select_filter.selected_routes.map((route) => route.id).includes(route.id);
            return (
                // filter_state?.asset_type_filters?.find(i => i.asset_type === 'Vehicle')?.enabled && //hide along with asset type filter
                filterRoute(route) && !is_selected ? ([buildRouteMarkers(route, false)]) : null
            )
        });
        filter_state.select_filter.selected_routes.forEach((route) => {
            if (filterRoute(route)) {
                markers.push([buildRouteMarkers(route, true)]);
            }
        })
        return markers;
    };

    //Extras for selected routes (speed forensics)
    const [detailed_selected_route_elements, setDetailedSelectedRouteElements] = useState<any>([]);
    const [lock_selected, setLockSelected] = useState(false);
    useEffect(() => {
        if (filter_state.select_filter.detailed_selected_route && filter_state.select_filter.selected_routes.length === 1) {
            if (!lock_selected) {
                setLockSelected(true);
                const asset = assets_state.assets.find((asset) => asset.tag_id == filter_state.select_filter.detailed_selected_route!.tag_id);
                if (asset) {
                    tagUploadService.getBetweenDateTime(filter_state.select_filter.detailed_selected_route.device_start_time, filter_state.select_filter.detailed_selected_route.device_end_time, asset?.hardware_id).then((results) => {
                        const tag_uploads = results.data.results;
                        if (tag_uploads) {
                            const r = buildDetailedSelectedRouteElements(filter_state.select_filter.detailed_selected_route!, tag_uploads);
                            setDetailedSelectedRouteElements(r);
                            setLockSelected(false);
                        }
                    })
                }
            }
        } else {
            setDetailedSelectedRouteElements([]);
        }
    }, [
        filter_state.select_filter.detailed_selected_route,
    ])

    const buildDetailedSelectedRouteElements = (selected_route: Route, tag_uploads: any[]) => {
        const buildDetailedSelectedRouteMarkers = (route: Route) => {
            const length = route.coordinates.length;
            const start = route?.coordinates[0];
            const end = route?.coordinates[length - 1];
            const is_assigned = route.list_of_sub_projects.length > 0;
            const asset = assets_state.assets.find((asset) => asset.tag_id == route.tag_id);
            return tag_uploads.map((tag_upload, index) => {
                const previous_coordinate = index > 0 ? { lat: tag_uploads[index - 1].latitude, lng: tag_uploads[index - 1].longitude } : null;
                const this_coordinate = { lat: tag_upload.latitude, lng: tag_upload.longitude };
                const previous_timestamp = index > 0 ? dayjs(tag_uploads[index - 1].device_timestamp) : null;
                const this_timestamp = index > 0 ? dayjs(tag_upload.device_timestamp) : null;
                const time_difference_seconds = previous_timestamp?.diff(this_timestamp, 'seconds');
                const distance_meters = previous_coordinate && this_coordinate ? geolib.getDistance(previous_coordinate, this_coordinate) : null;
                // const speed_kph = distance_meters && time_difference_seconds ? (distance_meters / 1000) / (time_difference_seconds / 60 / 60) : null;
                const speed_kph = tag_upload?.speed_kph;

                return tag_upload?.speed_kph ? [
                    <CircleMarker
                        key={`upload-${tag_upload.id}-${tag_upload.latitude}=${tag_upload.longitude}`}
                        center={[tag_upload.latitude, tag_upload.longitude]}
                        pathOptions={{ color: speed_kph ? speed_kph >= 120 ? 'purple' : speed_kph >= 110 ? 'red' : speed_kph >= 100 ? 'darkorange' : speed_kph >= 90 ? '#FFCC00' : speed_kph >= 80 ? 'green' : 'blue' : 'gray' }}
                        radius={5}
                    >
                        <TooltipLeaflet
                            key={`tu-tooltip-${tag_upload.id}`}
                            direction='bottom'
                            offset={[0, 4]}
                            permanent
                            opacity={0.75}
                        >
                            <Stack direction='column'>
                                <Typography variant='caption'>
                                    {tag_upload?.speed_kph?.toFixed(1) || ''}
                                </Typography>
                            </Stack>
                        </TooltipLeaflet>
                    </CircleMarker>,
                ] : null;
            })
        }
        return buildDetailedSelectedRouteMarkers(selected_route);
    };

    const [work_elements, setWorkElements] = useState<any>([]);
    useEffect(() => {
        const w = buildWorkElements();
        setWorkElements(w);
    }, [
        filtered_works,
        filter_state.work_filter,
        filter_state.asset_type_filters,
        filter_state.select_filter.selected_asset,
        filter_state.select_filter.selected_works,
        filter_state.show_ignored_filter,
    ])

    const buildWorkElements = () => {
        const buildWorkMarkers = (work: Work, is_selected: boolean) => {
            const asset = assets_state.assets.find((asset) => asset.tag_id == work.tag_id);
            const is_assigned = work.list_of_sub_projects.length > 0;
            const in_progress = work.device_duration !== null ? false : true;
            return [
                <CircleMarker
                    key={`work-${work.id}`}
                    className={in_progress ? 'animated-circle-marker' : undefined}
                    center={[work.latitude || 0, work.longitude || 0]}
                    pathOptions={{ color: is_selected ? 'black' : is_assigned ? 'gray' : in_progress ? 'red' : 'purple' }}
                    radius={in_progress ? 30 : 20}
                    eventHandlers={{
                        click: (e) => {
                            L.DomEvent.stopPropagation(e);
                            dispatch(setSelectFilterSelectedAsset(asset));
                            // Check if the Ctrl key is pressed (or Command key on macOS)
                            if (asset?.asset_type === 'Vehicle') {
                                props.setAssetInfo(
                                    <AssetInfoVehicle map={props.map} asset={asset} />
                                )
                            }
                            if (e.originalEvent.ctrlKey) {
                                if (!filter_state.select_filter.selected_works.map((work) => work.id).includes(work.id))
                                    dispatch(addSelectFilterSelectedWorks([work]));
                                else {
                                    dispatch(removeSelectFilterSelectedWorks([work]));
                                }
                            }
                            else {
                                // Regular click without Ctrl key: Clear previous selections and select the current Polyline
                                dispatch(setSelectFilterSelectedRoutes([]));
                                dispatch(addSelectFilterSelectedWorks([work]));
                            }
                        },
                        // contextmenu: (e: LeafletMouseEvent) => {
                        //     L.DomEvent.stopPropagation(e);
                        //     dispatch(setSelectFilterSelectedAsset(asset));
                        //     dispatch(addSelectFilterSelectedWorks([work]));
                        //     setSelectedAsset(asset);
                        //     setSelectedRoutes(filter_state.select_filter.selected_routes);
                        //     setSelectedWorks(filter_state.select_filter.selected_works);
                        //     handleClickAssignProject(e);
                        // }
                    }}
                >
                </CircleMarker>,
            ]
        }
        let markers = (filtered_works || []).map((work: Work) => {
            const is_selected = filter_state.select_filter.selected_works.map((work) => work.id).includes(work.id);
            return (
                // filter_state?.asset_type_filters?.find(i => i.asset_type === 'Vehicle')?.enabled && //hide along with asset type filter
                filterWork(work) && !is_selected ? ([buildWorkMarkers(work, false)]) : null
            )
        });
        filter_state.select_filter.selected_works.forEach((work) => {
            if (filterWork(work)) {
                markers.push([buildWorkMarkers(work, true)]);
            }
        })
        return markers;
    };

    const [asset_elements, setAssetElements] = useState<any>([]);
    useEffect(() => {
        const a = assets_state.assets.map((asset: Asset) => buildAssetElements(asset));
        setAssetElements(a);
    }, [
        assets_state.assets,
        filter_state.asset_type_filters,
    ])
    const buildAssetElements = (asset: Asset) => {
        return (filter_state?.asset_type_filters?.find(i => i.asset_type === asset.asset_type)?.enabled && asset.latitude && asset.longitude) ? (
            <Marker
                position={[asset?.latitude, asset?.longitude]}
                key={`marker-${asset.id}`}
                icon={
                    asset?.asset_type == 'Vehicle' ?
                        asset?.coordinate_type == 'Navigating' ? VEHICLE_GREEN : VEHICLE_BLANK :
                        asset?.asset_type == 'Employee' ? EMPLOYEE :
                            asset?.asset_type == 'Installation' ? INSTALLATION : INSTALLATION
                }
                title={asset.name}
                eventHandlers={{
                    click: (e) => {
                        props.map.setView([asset.latitude, asset.longitude], props.map.getZoom())
                        if (asset.asset_type === 'Vehicle') {
                            props.setAssetInfo(
                                <AssetInfoVehicle map={props.map} asset={asset} />
                            )
                        }
                        if (asset.asset_type === 'Installation') {
                            props.setAssetInfo(
                                <AssetInfoInstallation asset={asset} />
                            )
                        }
                        if (asset.asset_type === 'Employee') {
                            props.setAssetInfo(
                                <AssetInfoEmployee map={props.map} asset={asset} />
                            )
                        }
                        dispatch(setSelectFilterSelectedAsset(asset));
                    }
                }}
            >
                <TooltipLeaflet
                    direction='bottom'
                    offset={[0, 24]}
                    permanent
                >
                    {asset.name}
                </TooltipLeaflet>
            </Marker>
        ) : null;
    }

    const [current_route_elements, setCurrentRouteElements] = useState<any>([]);
    useEffect(() => {
        const c = buildCurrentRouteElements();
        setCurrentRouteElements(c);
    }, [
        tags_state.tags,
    ])

    const buildCurrentRouteElements = () => {
        const buildCurrentRouteMarkers = (tag: Tag, is_selected: boolean) => {
            if (tag.current_route) {
                const route = tag.current_route;
                const length = route.length;
                const start = route[0];
                const end = route[length - 1];
                const asset = assets_state.assets.find((asset) => asset.tag_id == tag.id);
                const active = asset !== undefined ? !isVehicleInactive(asset) : false;
                return active === true ?
                    [
                        <CircleMarker
                            key={`start-${tag.id}`}
                            center={[start.lat, start.lng]}
                            pathOptions={{ color: 'green' }}
                            radius={is_selected ? 10 : 5}
                        >
                        </CircleMarker>,
                        <Polyline
                            key={`current-route-${tag.id}`}
                            className='ant-path-current'
                            positions={route}
                            pathOptions={{ color: 'blue', weight: 2, dashArray: '4, 4' }}
                            eventHandlers={{
                                click: (e: LeafletMouseEvent) => {
                                    L.DomEvent.stopPropagation(e);
                                    if (asset?.asset_type === 'Vehicle') {
                                        props.setAssetInfo(
                                            <AssetInfoVehicle map={props.map} asset={asset} />
                                        )
                                    }
                                },
                            }}
                        />,
                        <Polyline
                            key={`current-route-${tag.id}-clickable`}
                            positions={route}
                            pathOptions={{ weight: 4, opacity: 0 }}
                            eventHandlers={{
                                click: (e: LeafletMouseEvent) => {
                                    L.DomEvent.stopPropagation(e);
                                    if (asset?.asset_type === 'Vehicle') {
                                        props.setAssetInfo(
                                            <AssetInfoVehicle map={props.map} asset={asset} />
                                        )
                                    }
                                },
                            }}
                        />,
                    ]
                    :
                    []
            }
            else {
                return null;
            }
        }
        //Selected marker drawn last to put it on top layer
        let markers = (tags_state.tags || []).map((tag: Tag) => {
            return (
                [buildCurrentRouteMarkers(tag, false)]
            )
        });
        return markers;

    };

    const handleClickCenterMap = () => {
        props.map.setView(initialState.center, initialState.zoom);
    };

    const isVehicleInactive = (asset: Asset | undefined) => {
        if (asset) {
            return (asset.coordinate_type === 'Navigating' || asset.coordinate_type === 'Start' || asset.coordinate_type === 'End') && //If navigating
                dayjs.duration(dayjs().diff(asset.device_update_timestamp), 'milliseconds').asHours() > 1; //If last seen more than an hour ago
        } else {
            return true;
        }
    }


    return (
        <MapContainer
            style={{ height: '100%', width: '100%', minHeight: '400px' }}
            center={center}
            zoom={zoom}
            maxZoom={map_max_zoom}
            attributionControl={true}
            zoomControl={false}
            ref={props.refmap}
        >
            <HandleMapClick />
            <HandleMapRightClick />
            <HandleMapZoom />
            {/* <LayersControl position='topleft'>
                <LayersControl.Overlay name='Vehicles' checked />
                <LayersControl.Overlay name='Employees' />
                <LayersControl.Overlay name='Installations' />
                <LayersControl.Overlay name='Routes' checked />
            </LayersControl> */}

            <MarkerClusterGroup //https://akursat.gitbook.io/marker-cluster/api
                maxClusterRadius={40}
                spiderfyOnMaxZoom={true}
                showCoverageOnHover={false}
                spiderfyDistanceMultiplier={3}
                removeOutsideVisibleBounds={true}
                chunkedLoading={true}
                // disableClusteringAtZoom={map_max_zoom}
                iconCreateFunction={createClusterCustomIcon}
                zoomToBoundsOnClick={true}
            >
                {asset_elements}
            </MarkerClusterGroup>

            {
                filter_state.route_filter.enabled && <FeatureGroup>
                    {route_elements}
                    {current_route_elements}
                    {detailed_selected_route_elements}
                </FeatureGroup>
            }

            {
                filter_state.work_filter.enabled && <FeatureGroup>
                    {work_elements}
                </FeatureGroup>
            }

            <LayersControl position='topright'>
                <BaseLayer checked name='Google Standard'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        subdomains={['mt1', 'mt2', 'mt3']}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='OpenStreetMap'>
                    <TileLayer
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='Google Hybrid'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                        subdomains={['mt1', 'mt2', 'mt3']}
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='Google Satellite'>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        subdomains={['mt1', 'mt2', 'mt3']}
                        maxNativeZoom={22}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
                <BaseLayer name='ArcGIS Satellite'>
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        maxNativeZoom={20}
                        maxZoom={map_max_zoom}
                        attribution={version}
                    />
                </BaseLayer>
            </LayersControl>
            <ZoomControl position='topright'></ZoomControl>
            <Control prepend position='bottomright'>
                <TooltipMui title={'Center map'}>
                    <IconButton color='inherit' onClick={handleClickCenterMap}>
                        <FilterCenterFocusIcon />
                    </IconButton>
                </TooltipMui>
            </Control>
            <Control prepend position='topleft'>
                {
                    filter_state.select_filter.detailed_selected_route &&
                    <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} spacing={1}>
                        <Paper>
                            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ p: 0.5 }}>
                                {
                                    lock_selected ?
                                        <Stack direction={'row'} alignContent={'center'} justifyContent={'space-between'} width={'100%'} spacing={1}>
                                            <Typography variant='caption' fontWeight={'bold'}>
                                                Loading route data
                                            </Typography>
                                            <CircularProgress size={'1rem'} />
                                        </Stack>
                                        :
                                        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} minWidth={'200px'} spacing={1}>
                                            <Typography variant='caption' fontWeight={'bold'}>
                                                {`${filter_state.select_filter.detailed_selected_route?.asset_routes.find(ar => ar.asset?.asset_type.toLowerCase() === 'vehicle')?.asset?.name}`}
                                            </Typography>
                                            <Stack direction={'column'} justifyContent={'space-between'} width={'100%'} spacing={0}>
                                                <Typography variant='caption' fontWeight={'bold'}>
                                                    {`Route ${filter_state.select_filter.detailed_selected_route?.id}`}
                                                </Typography>
                                                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                                    <Typography variant='caption'>
                                                        {`Start`}
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        {`${dayjs(filter_state.select_filter.detailed_selected_route?.device_start_time).format('DD/MM/YYYY HH:mm:ss')}`}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                                    <Typography variant='caption'>
                                                        {`End`}
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        {`${dayjs(filter_state.select_filter.detailed_selected_route?.device_end_time).format('DD/MM/YYYY HH:mm:ss')}`}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                                <Typography variant='caption' fontWeight={'bold'}>
                                                    {`Distance`}
                                                </Typography>
                                                <Typography variant='caption' fontWeight={'bold'}>
                                                    {`${(filter_state.select_filter.detailed_selected_route?.approximate_distance / 1000).toFixed(1)} [km]`}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={'column'} justifyContent={'space-between'} width={'100%'} spacing={0}>
                                                <Typography variant='caption' fontWeight={'bold'}>
                                                    {`Speed`}
                                                </Typography>
                                                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                                    <Typography variant='caption'>
                                                        {`Average`}
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        {`${filter_state.select_filter.detailed_selected_route?.average_speed.toFixed(1)} [km/h]`}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                                    <Typography variant='caption'>
                                                        {`Max`}
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        {`${filter_state.select_filter.detailed_selected_route?.max_speed.toFixed(1)} [km/h]`}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction={'column'} justifyContent={'space-between'} width={'100%'} spacing={0}>
                                                    <Typography variant='caption' fontWeight={'bold'}>
                                                        {`Legend`}
                                                    </Typography>
                                                    <Typography variant='caption' color={'purple'} fontWeight={'bold'}>
                                                        {`> 120 [km/h]`}
                                                    </Typography>
                                                    <Typography variant='caption' color={'red'} fontWeight={'bold'}>
                                                        {`> 110 [km/h]`}
                                                    </Typography>
                                                    <Typography variant='caption' color={'darkorange'} fontWeight={'bold'}>
                                                        {`> 100 [km/h]`}
                                                    </Typography>
                                                    <Typography variant='caption' color={'#FFCC00'} fontWeight={'bold'}>
                                                        {`> 90 [km/h]`}
                                                    </Typography>
                                                    <Typography variant='caption' color={'green'} fontWeight={'bold'}>
                                                        {`> 80 [km/h]`}
                                                    </Typography>
                                                    <Typography variant='caption' color={'blue'} fontWeight={'bold'}>
                                                        {`< 80 [km/h]`}
                                                    </Typography>
                                                </Stack>
                                            </Stack>

                                        </Stack>
                                }
                            </Stack>
                        </Paper>
                    </Stack>
                }
            </Control>
            {
                selected_asset && selected_route && selected_work &&
                <Menu
                    id='map-polyline-project-assign-menu'
                    anchorReference='anchorPosition'
                    anchorPosition={anchorPosition}
                    open={open_project_assign_menu}
                    onClose={handleCloseAssignProjectMenu}
                    onKeyDown={(e) => e.stopPropagation()}
                    MenuListProps={{ sx: { p: 0 } }}
                    transitionDuration={0}
                >
                    <ProjectAssignMenu
                        asset={selected_asset}
                        routes={selected_route}
                        works={selected_work}
                        handleCloseAssignProjectMenu={handleCloseAssignProjectMenu}
                        show_map={false}
                    />
                </Menu>
            }
        </MapContainer >
    )
};

export default MapAssets;
