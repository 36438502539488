import { useState, useEffect } from 'react';
import { Button, Card, Divider, List, ButtonGroup, Popover, Tooltip } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TokenIcon from '@mui/icons-material/Token';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { useSelector } from 'react-redux';
import { AssetsState } from '../../slices/assets';
import { FilterState, selectAssetTypeFilter } from '../../slices/filter';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { addAssetTypeFilter, enableAssetTypeFilter } from '../../slices/filter';
import { AssetTypeFilter } from '../../slices/filter';
import { Typography } from '@mui/material';

export const AssetTypeFilterButton = (props: { asset_type_filter: AssetTypeFilter }) => {
    const dispatch = useDispatch<AppDispatch>();

    const iconChooser = (asset_type: any) => {
        if (asset_type == 'Vehicle') {
            return <DirectionsCarIcon />;
        }
        if (asset_type == 'Employee') {
            return <EngineeringIcon />;
        }
        if (asset_type == 'Installation') {
            return <TokenIcon />;
        }
        return <ViewInArIcon />;
    }

    return (
        <Tooltip title={props.asset_type_filter.asset_type}>
            <Button
                size='small'
                variant='contained'
                color={props.asset_type_filter.enabled ? 'primary' : 'inherit'}
                onClick={() => {
                    dispatch(enableAssetTypeFilter({ id: props.asset_type_filter.id, enabled: !props.asset_type_filter.enabled }));
                }}
                onDoubleClick={() => {
                    dispatch(selectAssetTypeFilter({ id: props.asset_type_filter.id }));
                }}
            >
                {iconChooser(props.asset_type_filter?.asset_type)}
            </Button>
        </Tooltip>
    );
}
